import React from "react";

import { useTranslation } from "react-i18next";
import "../../i18n";

import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";

import Background from "../BackgroundNew";

import {
  AuthenticateMessageContainer,
  AuthenticateMessage,
  AuthenticateMessageExplanation,
  AuthenticateMessageIcon,
} from "./styled";

export const OAuthAuthenticateMessage = ({ isSuccess }) => {
  const { t } = useTranslation();

  return (
    <Background>
      <AuthenticateMessageContainer>
        <AuthenticateMessageIcon isSuccess={isSuccess}>
          {isSuccess ? <FaRegCircleCheck /> : <RxCrossCircled />}
        </AuthenticateMessageIcon>
        <AuthenticateMessage>
          {isSuccess ? t("oauth.authentication_success") : t("oauth.authentication_failed")}
        </AuthenticateMessage>
        <AuthenticateMessageExplanation>{t("oauth.close_window_message")}</AuthenticateMessageExplanation>
      </AuthenticateMessageContainer>
    </Background>
  );
};
