import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../i18n";

import Loader from "../../components/Loader";
import { Container, Login } from "./styles";
import logo from "../../assets/logo_preto_sem_digital_v2.png";
import { authenticate, resetPassword } from "../../services/odinService";
import { editMyUserSettings } from "../../services/odinService";
import { setUserInfo, getPreviousPath, removePreviousPath, saveToken } from "../../utils/loginUtils";
import { routes } from "../../routes";

import { AuthContext } from "../../contexts";

function SignIn() {
  const { authenticated, handleSignInSuccess } = useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pushToken, setPushToken] = useState("");
  const [forgetPassword, setForgetPassword] = useState(false);
  const [forgetPasswordUsername, setForgetPasswordUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getPushTokenFromQueryStringURL = useCallback(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    let pushToken = "";

    searchParams.forEach(function (value, key) {
      if (key && !value) {
        pushToken = key;
      }
    });

    return pushToken;
  }, []);

  const data = {
    username,
    password,
  };

  const handleShowForgetPassword = () => {
    setForgetPassword(true);
    setUsername("");
    setPassword("");
  };

  const handleCancelForgetPassword = () => {
    setForgetPassword(false);
    setForgetPasswordUsername("");
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    if (!forgetPasswordUsername) {
      toast.error(t("toast.username_required"));
    } else {
      try {
        setIsLoading(true);

        await resetPassword(forgetPasswordUsername);
        toast.success(t("toast.password_reset_success"));

        handleCancelForgetPassword();
      } catch {
        toast.error(t("toast.password_reset_failed"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  async function HandleLogin(e) {
    e.preventDefault();

    if (!data.username || !data.password) {
      toast.error(t("toast.fill_all_fields_try_again"));
    } else {
      try {
        setIsLoading(true);

        const access_token = await authenticate(data.username, data.password);

        saveToken(access_token);
        await setUserInfo(access_token);

        const currentUser = JSON.parse(localStorage.getItem("user"));
        if (!currentUser) {
          throw Error("User not found");
        }

        await handleSignInSuccess(currentUser, access_token);

        let appToken = pushToken;
        if (!appToken) {
          appToken = getPushTokenFromQueryStringURL();
        }

        if (!currentUser.is_company_owner_user && appToken) {
          await editMyUserSettings({
            app_token: appToken,
          });
        }

        if (!currentUser.is_company_owner_user && !window.OneSignal.pendingInit && !appToken) {
          const webPushActive = await window.OneSignal.isPushNotificationsEnabled();
          const playerId = await window.OneSignal.getUserId();

          if (!webPushActive) {
            window.OneSignal.showSlidedownPrompt({ force: true });
          }

          await editMyUserSettings({
            web_token: webPushActive ? playerId : "",
          });
        }

        const previousPath = getPreviousPath();

        if (previousPath) {
          removePreviousPath();
          navigate(previousPath);
        } else {
          navigate(routes.goalsOverview);
        }
      } catch (error) {
        toast.error(t("toast.auth_failed_try_again"));
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (authenticated) {
      navigate(routes.goalsOverview);
    }

    setPushToken(getPushTokenFromQueryStringURL());
  }, [authenticated, navigate, getPushTokenFromQueryStringURL]);

  return (
    <Container>
      <Login>
        <img src={logo} alt="Biso" />
        <form onSubmit={forgetPassword ? handleForgetPassword : HandleLogin}>
          <div className="containerTitle">
            <p className="welcomeTitle">{t("signIn.welcome")}</p>
            <p className="title">
              {forgetPassword ? t("signIn.password_recovery") : t("signIn.access_dashboard")}
            </p>
            <p className="descriptionTitle">
              {forgetPassword ? t("signIn.enter_login_recover_password") : t("signIn.enter_login_password")}
            </p>
          </div>

          {forgetPassword && (
            <>
              <p className="descriptionForgetPassword">{t("signIn.password_reset_instructions")}</p>
              <div className="input">
                <input
                  type="text"
                  placeholder={t("users.user")}
                  value={forgetPasswordUsername}
                  onChange={(value) => setForgetPasswordUsername(value.target.value)}
                />
              </div>

              <button type="submit" disabled={isLoading}>
                {t("signIn.send")}
                {isLoading && (
                  <Loader
                    size={20}
                    color="#fff"
                    containerStyles={{ width: "auto", height: "auto", marginLeft: 5 }}
                  />
                )}
              </button>

              <div className="signin">
                <span onClick={handleCancelForgetPassword}>{t("signIn.log_in")}</span>
              </div>
            </>
          )}
          {!forgetPassword && (
            <>
              <div className="input">
                <input
                  type="text"
                  placeholder={t("users.user")}
                  value={username}
                  onChange={(value) => setUsername(value.target.value)}
                />
              </div>

              <div className="input">
                <input
                  type="password"
                  placeholder={t("users.password")}
                  value={password}
                  onChange={(value) => setPassword(value.target.value)}
                />
              </div>

              <button type="submit" disabled={isLoading}>
                {t("signIn.access_now")}
                {isLoading && (
                  <Loader
                    size={20}
                    color="#fff"
                    containerStyles={{ width: "auto", height: "auto", marginLeft: 5 }}
                  />
                )}
              </button>

              <div className="forgotPassword">
                <span onClick={handleShowForgetPassword}>{t("signIn.forgot_password")}</span>
              </div>
            </>
          )}
        </form>
      </Login>
    </Container>
  );
}

export default SignIn;
