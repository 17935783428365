import * as React from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SelectStyled, MenuItemStyled } from "./styles";
import { useTranslation } from 'react-i18next';
import '../../i18n';

const BasicSelect = ({ placeholder, options, value, setValue, width, height, additonalStyles }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);

    if (searchValue === "") {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  return (
    <Box sx={{ minWidth: width, ...additonalStyles }}>
      <FormControl fullWidth>
        <InputLabel id="simple-select-label" sx={{ color: "#ff0068", "&.Mui-focused": { color: "#ff0068" } }}>
          {placeholder}
        </InputLabel>
        <SelectStyled
          labelId="simple-select-label"
          id="simple-select"
          variant='outlined'
          value={value}
          label={placeholder}
          onChange={(event) => setValue(event.target.value)}
          sx={{ height: height }}
          renderValue={(selected) => {
            if (!selected) {
              return placeholder;
            }
            const selectedOption = options.find(option => option.value === selected);
            return selectedOption ? selectedOption.label : '';
          }}
        >
          <Box sx={{ padding: '8px', backgroundColor: '#f5f5f5' }}>
            <Input
              placeholder={t('common.search')}
              value={searchText}
              onChange={handleSearchChange}
              onKeyDown={(e) => e.stopPropagation()}
              fullWidth
              sx={{
                '&::before': { borderColor: 'rgba(0, 0, 0, 0.20)' }, 
                '&::after': { borderColor: '#ff0068' }, 
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#ff0068' },
                fontSize: '14px',
              }}
            />
          </Box>

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <MenuItemStyled key={option.value} value={option.value}>
                {option.label}
              </MenuItemStyled>
            ))
          ) : (
            <MenuItemStyled disabled sx={{ justifyContent: 'center' }}>
              {t('common.no_results_found', 'Nenhum resultado encontrado')}
            </MenuItemStyled>
          )}
        </SelectStyled>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
