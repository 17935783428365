import React, { useEffect, useCallback, useState, useContext } from "react";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../i18n";

import { AuthContext } from "../../contexts";

import { getCurrentYear, getMonthName } from "../../utils/dateUtils";
import { formatValueToMoney, formatValueToNumber } from "../../utils/metricsUtils";

import Loader from "../../components/Loader";
import SelectOption from "../../components/SelectOption";
import BackgroundNew from "../../components/BackgroundNew";

import { CompanyGoalsView } from "./CompanyGoalsView";
import { CreateOrEditCompanyGoals } from "./CreateOrEditCompanyGoals";

import { Container, LoadingContainer, EmptyMessage, ActionsContainer, Button } from "./styles";

import { getCustomCompanyGoalsGroupByMonth } from "../../services/customerService";

const GoalsManager = () => {
  const { t } = useTranslation();

  const { user, currency } = useContext(AuthContext);

  const [showLoading, setShowLoading] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYearOption, setSelectedYearOption] = useState(null);
  const [companyGoals, setCompanyGoals] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [showCreateOrEditComponent, setShowCreateOrEditComponent] = useState(false);
  const [selectedEditMonth, setSelectedEditMonth] = useState(0);

  const getYearsOptionsFromYears = useCallback(() => {
    const currentYear = getCurrentYear();
    const startYear = 2020;
    const years = [];

    for (let year = startYear; year <= currentYear + 1; year++) {
      years.push(year);
    }

    const options = years.map((year) => {
      return {
        value: year,
        label: `${t("common.year")}/${year}`,
      };
    });

    return [{ label: `${t("common.year")}`, options }];
  }, [t]);

  const getCompanyGoals = useCallback(async (year) => {
    setShowLoading(true);

    try {
      const response = await getCustomCompanyGoalsGroupByMonth(year);

      if (!response) {
        setCompanyGoals(null);
        return;
      }

      setCompanyGoals(response[0].goals_month);
    } catch (error) {
      toast.error(error.message);

      setCompanyGoals(null);
    } finally {
      setShowLoading(false);
    }
  }, []);

  const handleChangeActiveYear = async (select, _) => {
    setSelectedYearOption(select);

    const yearNumber = parseInt(select.value);

    await getCompanyGoals(yearNumber);
  };

  const handleEdit = (month) => {
    setSelectedEditMonth(month);
    setEditMode(true);
    setShowCreateOrEditComponent(true);
  };

  const handleCreate = () => {
    setEditMode(false);
    setShowCreateOrEditComponent(true);
  };

  const handleCancel = () => {
    setSelectedEditMonth(0);
    setEditMode(false);
    setShowCreateOrEditComponent(false);
  };

  const onRenderComponent = useCallback(async () => {
    const yearsOptions = getYearsOptionsFromYears();
    setYearOptions(yearsOptions);

    const currentYear = getCurrentYear();
    const optionYear = yearsOptions[0].options.find((option) => option.value === currentYear);
    setSelectedYearOption(optionYear);

    await getCompanyGoals(currentYear);
  }, [getCompanyGoals, getYearsOptionsFromYears]);

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <BackgroundNew
      titlePage={t("menu.goal_management")}
      subTitlePage={
        !editMode && !showCreateOrEditComponent
          ? t("goalsManager.manage_goals")
          : editMode
          ? "Edite suas metas"
          : "Crie suas metas"
      }
    >
      {showCreateOrEditComponent ? (
        <CreateOrEditCompanyGoals
          handleCancel={handleCancel}
          getCompanyGoals={getCompanyGoals}
          year={selectedYearOption.value}
          month={selectedEditMonth}
          companyGoalsGroupByMonth={companyGoals}
          editMode={editMode}
          currency={currency}
        />
      ) : (
        <Container>
          <ActionsContainer>
            <SelectOption
              options={yearOptions}
              value={selectedYearOption}
              onChange={handleChangeActiveYear}
              isDisabled={showLoading}
            />
            {user?.is_company_owner_user && (
              <Button onClick={handleCreate} disabled={showLoading} style={{ marginLeft: "10px" }}>
                {t("goalsManager.create_goals")}
              </Button>
            )}
          </ActionsContainer>
          {showLoading ? (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          ) : companyGoals == null ? (
            <EmptyMessage>{t("goalsManager.not_created_yet")}</EmptyMessage>
          ) : (
            <CompanyGoalsView
              data={companyGoals}
              formatValueToMoney={(value) => formatValueToMoney(value, currency)}
              formatValueToNumber={formatValueToNumber}
              getMonthName={getMonthName}
              handleEdit={handleEdit}
              showEditButton={user?.is_company_owner_user}
            />
          )}
        </Container>
      )}
    </BackgroundNew>
  );
};

export default GoalsManager;
