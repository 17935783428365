import Select from "@mui/material/Select";
import { styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export const SelectStyled = styled(Select)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  fontSize: 14,
  backgroundColor: "#fff",
  borderRadius: "40px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.20)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.30)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ff0068",
  },
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#ff0068",
    color: "#fff",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#e6005c",
    color: "#fff",
  },
  fontSize: 14,
}));
