import React, { useEffect, useState, useCallback } from "react";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../../i18n";

import Input from "../../../components/Input";
import CustomModal from "../../../components/CustomModal/CustomModal";

import { editLoggedUserPassword } from "../../../services/odinService";
import { useMemo } from "react";

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const CHANGE_PASSWORD_ERROR_MESSAGE_DICT = useMemo(
    () => ({
      ERR_OLD_PASSWORD_INCORRECT: t("profile.old_password_incorrect"),
      ERR_PASSWORD_MISSING_UPPERCASE_LOWERCASE: t("profile.password_requirements"),
      ERR_PASSWORD_MISSING_SPECIAL_CHARS: t("profile.password_requirements"),
      ERR_PASSWORD_MISSING_LENGTH: t("profile.password_requirements"),
      ERR_PASSWORD_MISSING_NUMBERS: t("profile.password_requirements"),
    }),
    [t]
  );

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const validatePassword = useCallback(
    (_) => {
      let isValid = true;

      if (!oldPassword || !newPassword || !confirmNewPassword) {
        toast.error(t("toast.fill_all_fields"));
        isValid = false;

        return isValid;
      }

      if (newPassword !== confirmNewPassword) {
        toast.error(t("toast.password_mismatch"));
        isValid = false;
      }

      const regexInvalidCharacters = /['"\s`\\]/;
      if (regexInvalidCharacters.test(newPassword)) {
        toast.error(t("toast.password_invalid_chars"));
        isValid = false;
      }

      const regexStrongPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!regexStrongPassword.test(newPassword)) {
        toast.error(t("toast.password_requirements"));
        isValid = false;
      }

      return isValid;
    },
    [newPassword, confirmNewPassword, oldPassword, t]
  );

  const handleSave = useCallback(async () => {
    if (!validatePassword()) return;

    setLoading(true);
    try {
      await editLoggedUserPassword({
        old_password: oldPassword,
        new_password: newPassword,
      });

      toast.success(t("toast.password_changed_success"));
      onClose();
    } catch (error) {
      toast.error(CHANGE_PASSWORD_ERROR_MESSAGE_DICT[error.message] || error.message);
    }
    setLoading(false);
  }, [newPassword, onClose, validatePassword, CHANGE_PASSWORD_ERROR_MESSAGE_DICT, oldPassword, t]);

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  }, [isOpen]);

  return (
    <CustomModal
      open={isOpen}
      handleClose={onClose}
      title={t("profile.change_password")}
      handleConfirm={handleSave}
      confirmButtonLoading={loading}
      handleConfirmStatus={loading}
    >
      <Input
        label={t("profile.current_password")}
        type="password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <Input
        label={t("profile.new_password")}
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <Input
        label={t("profile.confirm_new_password")}
        type="password"
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
    </CustomModal>
  );
};

export default ChangePasswordModal;
