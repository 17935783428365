import { useNavigate } from "react-router-dom";
import React, { useEffect, useCallback, useState, useRef, useContext } from "react";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../i18n";

import { MatrixContainer } from "./styles";
import CardGroup from "../../components/CardGroup";
import BackgroundNew from "../../components/BackgroundNew";
import FilterContainer from "../../components/FilterContainer";
import { generateGradientColor } from "../../utils/colorUtils";
import { isValidAvailableFilter } from "../../utils/validationUtils";
import { getCRMRfvMetrics } from "../../services/dashboardApiService";
import { formatValueToMoney, formatValueToNumber } from "../../utils/metricsUtils";
import ModalPageInformation from "../../components/CustomModal/ModalPageInformation";
import GraphController from "../../components/GraphController";

import { routes } from "../../routes";

import { AuthContext } from "../../contexts";

const Rfv = () => {
  const { t } = useTranslation();
  const { timezone, currency } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [rfvRevenueMatrix, setRfvRevenueMatrix] = useState([]);
  const [rfvCustomersMatrix, setRfvCustomersMatrix] = useState([]);
  const [originsOptions, setOriginsOptions] = useState([]);
  const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
  const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
  const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
  const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
  const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
  const [openModalInformation, setOpenModalInformation] = useState(false);

  const [headers, setHeaders] = useState([]);

  const cardPurchaseFrequencyByRecencyByValueRef = useRef(null);
  const cardPurchaseFrequencyByRecencyForNumberOfCustomersRef = useRef(null);

  const handleApplyFilter = async () => {
    const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
    const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
    const vtexAffiliates =
      selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;

    await getCRMDataMetrics(origins, salesChannels, vtexAffiliates);
  };

  const extractDataToMatrices = useCallback(
    (data) => {
      const revenueMatrix = [];
      const customersMatrix = [];

      const categoryKeys = new Set();
      const externalKeys = new Set();

      for (const key in data) {
        externalKeys.add(key);
        for (const rKey in data[key]) {
          categoryKeys.add(rKey);
        }
      }

      const sortedCategoryKeys = [...categoryKeys].sort();

      const headers = [t("rfv.frequency"), ...sortedCategoryKeys];
      setHeaders(headers);

      for (const externalKey of externalKeys) {
        const revenueRow = [externalKey];
        const customersRow = [externalKey];

        for (const categoryKey of sortedCategoryKeys) {
          const categoryData = data[externalKey][categoryKey];
          revenueRow.push(categoryData ? categoryData.revenue : 0);
          customersRow.push(categoryData ? categoryData.customers : 0);
        }

        revenueMatrix.push(revenueRow);
        customersMatrix.push(customersRow);
      }

      setRfvRevenueMatrix(revenueMatrix);
      setRfvCustomersMatrix(customersMatrix);
    },
    [t]
  );

  const getCRMDataMetrics = useCallback(
    async (origins = null, salesChannels = null, vtexAffiliates = null) => {
      setIsLoading(true);

      try {
        const response = await getCRMRfvMetrics(timezone, origins, salesChannels, vtexAffiliates);

        if (!response) {
          setRfvRevenueMatrix([]);
          setRfvCustomersMatrix([]);
          setOriginsOptions([]);
          setSalesChannelsOptions([]);
          setVtexAffiliatesOptions([]);

          return;
        }

        setOriginsOptions(response.available_origins_to_filter);
        setSalesChannelsOptions(response.available_marketplace_channels_to_filter);

        if (isValidAvailableFilter(response.available_affiliates_to_filter)) {
          setVtexAffiliatesOptions(response.available_affiliates_to_filter);
        } else {
          setVtexAffiliatesOptions([]);
        }

        delete response.available_origins_to_filter;
        delete response.available_marketplace_channels_to_filter;
        delete response.available_affiliates_to_filter;

        extractDataToMatrices(response);
      } catch (error) {
        toast.error(error.message);
        setRfvRevenueMatrix([]);
        setRfvCustomersMatrix([]);
      } finally {
        setIsLoading(false);
      }
    },
    [extractDataToMatrices, timezone]
  );

  const getSecondaryFilters = () => {
    return [
      {
        title: `${t("common.source")}`,
        options: originsOptions,
        value: selectedOriginsOptions,
        setValue: setSelectedOriginsOptions,
      },
      {
        title: t("common.sales_channel"),
        options: salesChannelsOptions,
        value: selectedSalesChannelsOptions,
        setValue: setSelectedSalesChannelsOptions,
      },
      {
        title: `${t("common.affiliates_vtex")}`,
        options: vtexAffiliatesOptions,
        value: selectedVtexAffiliatesOptions,
        setValue: setSelectedVtexAffiliatesOptions,
      },
    ];
  };

  const getCustomLabel = (value) => {
    const labels = {
      R1: t("rfv.r1_last_30_days"),
      R2: t("rfv.r2_31_to_60_days"),
      R3: t("rfv.r3_61_to_120_days"),
      R4: t("rfv.r4_121_to_180_days"),
      R5: t("rfv.r5_181_to_360_days"),
      R6: t("rfv.r6_more_than_360_days"),
      F1: t("rfv.f1_12_or_more_purchases"),
      F2: t("rfv.f2_7_to_11_purchases"),
      F3: t("rfv.f3_4_to_6_purchases"),
      F4: t("rfv.f4_2_to_3_purchases"),
      F5: t("rfv.f5_1_purchase"),
    };

    return value in labels ? labels[value] : value;
  };

  const calculateColumnTotal = (matrixData, columnIndex) => {
    return matrixData.reduce((total, row) => total + row[columnIndex], 0);
  };

  const calculateTotalSum = (matrixData, headers) => {
    const totalSum = headers.slice(1).reduce((total, _, colIndex) => {
      return total + calculateColumnTotal(matrixData, colIndex + 1);
    }, 0);

    return totalSum;
  };

  const renderMatriz = (matrixData, headers, formater) => {
    if (matrixData.length === 0) {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: 20,
            fontSize: 15,
            fontWeight: 200,
          }}
        >
          {t("common.no_data")}
        </div>
      );
    }

    const minValue = Math.min(...matrixData.map((row) => Math.min(...row.slice(1))));
    const maxValue = Math.max(...matrixData.map((row) => Math.max(...row.slice(1))));

    return (
      <MatrixContainer>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{getCustomLabel(header)}</th>
              ))}
              <th style={{ color: "#ff0068", fontWeight: "bold" }}>{t("common.total")}</th>
            </tr>
          </thead>
          <tbody>
            {matrixData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((value, colIndex) => (
                  <td
                    key={colIndex}
                    style={
                      colIndex === 0
                        ? { background: "transparent" }
                        : colIndex === headers.length
                        ? { background: "transparent" }
                        : { background: generateGradientColor(value, minValue, maxValue) }
                    }
                  >
                    {colIndex > 0 ? formater(value) : getCustomLabel(value)}
                  </td>
                ))}
                <td className="totalResult">
                  {formater(row.slice(1).reduce((total, value) => total + value, 0))}
                </td>
              </tr>
            ))}
            <tr className="totalResult">
              <td style={{ background: "transparent", color: "#ff0068", fontWeight: "bold" }}>
                {t("common.total")}
              </td>
              {headers.slice(1).map((_, colIndex) => (
                <td key={colIndex}>{formater(calculateColumnTotal(matrixData, colIndex + 1))}</td>
              ))}
              <td>{formater(calculateTotalSum(matrixData, headers))}</td>
            </tr>
          </tbody>
        </table>
      </MatrixContainer>
    );
  };

  const onRenderComponent = useCallback(async () => {
    await getCRMDataMetrics();
  }, [getCRMDataMetrics]);

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <BackgroundNew
      titlePage={t("menu.rfv")}
      showButtonAction
      buttonActionOnClick={(_) => navigate(routes.customerBehavior)}
      buttonActionTitle={t("rfv.segment_your_customers")}
      showInformation={true}
      informationTooltipTitle={t("common.click_to_learn_more")}
      informationOnClick={() => setOpenModalInformation(true)}
    >
      <FilterContainer
        showDateRangeFilter={false}
        showSecondaryFilters={true}
        secondaryFilters={getSecondaryFilters()}
        onClickFilter={handleApplyFilter}
      />
      <CardGroup
        title={t("rfv.purchase_frequency_recency_value_platform")}
        isLoading={isLoading}
        ref={cardPurchaseFrequencyByRecencyByValueRef}
      >
        <GraphController
          showDimensionController={false}
          showExportPDFButton={true}
          graphComponentRefForPDF={cardPurchaseFrequencyByRecencyByValueRef}
          style={{ margin: 15, marginRight: 0 }}
          disabled={rfvRevenueMatrix && rfvRevenueMatrix.length === 0}
        />
        {renderMatriz(rfvRevenueMatrix, headers, (value) => formatValueToMoney(value, currency))}
      </CardGroup>
      <CardGroup
        title={t("rfv.purchase_frequency_recency_number_of_customers_platform")}
        isLoading={isLoading}
        ref={cardPurchaseFrequencyByRecencyForNumberOfCustomersRef}
      >
        <GraphController
          showDimensionController={false}
          showExportPDFButton={true}
          graphComponentRefForPDF={cardPurchaseFrequencyByRecencyForNumberOfCustomersRef}
          style={{ margin: 15, marginRight: 0 }}
          disabled={rfvCustomersMatrix && rfvCustomersMatrix.length === 0}
        />
        {renderMatriz(rfvCustomersMatrix, headers, formatValueToNumber)}
      </CardGroup>
      <ModalPageInformation
        title={t("rfv.understand_rfv_analysis")}
        open={openModalInformation}
        handleClose={() => setOpenModalInformation(false)}
        videoUrl={"https://www.youtube.com/embed/-7bUFBya7KI?si=SAf8dejFix11tYSH"}
      />
    </BackgroundNew>
  );
};

export default Rfv;
