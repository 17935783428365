import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import {
  parseDateToDayjs,
  formatDate
} from '../../../utils/dateUtils';

import Loader from '../../Loader';

import { getModules, createUserModule } from '../../../services/AlertService';
import { testFacebookAdsAccountAccess } from '../../../services/validationAccountAccess';
import { 
  getAuthorizationUrl, exchangeCodeForToken, generateStateIdentifier 
} from '../../../services/OAuthService';

import * as alertModuleSetting from '../../../options/alertModuleSetting';

import { FaCheckCircle } from "react-icons/fa";

import * as S from './styled';

function CustomModalFacebookAds(props) {
  const { t } = useTranslation();

  const [faId, setFaId] = useState('');
  const [oAuthCompleted, setOauthCompleted] = useState(false);
  const [oAuthToken, setOAuthToken] = useState(null);
  const [oAuthExpirationDate, setOAuthExpirationDate] = useState(null);
  const [oAuthSuccess, setOAuthSuccess] = useState(false);
  const [oAuthInProgress, setOAuthInProgress] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const NOTIFICATION_DAYS_BEFORE_EXPIRATION = 10;

  const getNotificationDate = () => {
    const date = parseDateToDayjs(new Date(oAuthExpirationDate));
    const notificationDate = date.subtract(NOTIFICATION_DAYS_BEFORE_EXPIRATION, 'day');
    
    return formatDate(notificationDate);
  }

  const handleCancel = () => {
    setOAuthToken(null);
    setOAuthSuccess(false);
    setOauthCompleted(false);
    setOAuthInProgress(false);
    props.handleClose();
  }

  const handleFaSaveConfig = async () => {
    if (faId && oAuthToken) {
      setSavingInProgress(true);

      const userModules = await getModules();
      const setting = userModules.filter((item) => item.name === alertModuleSetting.types.FA)[0];

      toast.info(t('toast.connection_start'));

      if(await testFacebookAdsAccountAccess(`act_${faId}`, oAuthToken)) {
        const data = {
          active: true,
          settings: {
            FA: {
              profiles: JSON.stringify([
                { 
                  ID: `act_${faId}`,
                  TOKEN: oAuthToken,
                  EXPIRATION_DATE: oAuthExpirationDate,
                  NOTIFICATION_DATE: getNotificationDate()
                }
              ]),
            },
          },
        };

        try {
          await createUserModule(setting.id, data);
          
          handleCancel();
          props.handleGetUserSettings();
          toast.success(t('toast.configuration_saved'));
        } catch (error) {
          toast.error(t('toast.configuration_save_failed'));
        }
      } else {
        toast.error(t('toast.facebook_auth_access_check'));
      }

      setSavingInProgress(false);
    } else {
      toast.error(t('toast.facebook_account_id_required'));
    }
  };

  const initiateFacebookAdsOAuthFlow = async () => {
    try {
      setOAuthInProgress(true)

      const stateValue = generateStateIdentifier();

      const response = await getAuthorizationUrl('facebook', stateValue);
      window.open(response.url, '_blank');

      const handleMessage = async (event) => {
        try {
          const data = event.data;
          if (data && data.source === 'oAuthFacebookAds') {
            const { completed, success, code, state } = data;
  
            if (completed) {
              window.removeEventListener('message', handleMessage);
  
              if (!success || state !== stateValue) {
                setOauthCompleted(true);
                setOAuthSuccess(false);
  
                throw new Error();
              }
  
              setOauthCompleted(true);
  
              const responseToken = await exchangeCodeForToken('facebook', code);
              setOAuthToken(responseToken.token)
              setOAuthExpirationDate(responseToken.expires_date)
  
              setOAuthSuccess(true);
  
              toast.success(t('toast.auth_success'));
            }
          }
        } catch (error) {
          toast.error(t('toast.facebook_auth_failed'));
        } finally {
          setOAuthInProgress(false);
        }
      };
  
      window.addEventListener('message', handleMessage);
    } catch (error) {
      toast.error(t('toast.facebook_auth_initiate_failed'));
    }
  };
  
  return (
    <S.CustomModalFacebookAds {...props}>
      <div className="modal">
        <div className="modal__header">
          <p className="modal__title">{t('connectionAndChannel.facebook_ads_setup')}</p>
          <div className="line" />
        </div>

        <div className="modal__body">
          <div className="modal__slider">
            <form className="modal__form">
              <div className="modal__step-by-step">
                <ul>
                  <li>1. {t('connectionAndChannel.start_access_authorization')} <button className='modal__form-btn is--auth is--demonstrative' type="button">{t('connectionAndChannel.authorize_access')}</button></li>
                  <li>2. {t('connectionAndChannel.redirect_facebook_page')}</li>
                  <li>3. {t('connectionAndChannel.after_login_redirect')}: <button className='modal__form-btn is--auth is--authenticated is--demonstrative' type="button"><FaCheckCircle style={{marginRight: '5px'}} size={13} color='#fff'/> {t('connectionAndChannel.authenticated')}</button>.</li>
                  <li>4. {t('connectionAndChannel.enter_facebook_ads_id')}</li>
                </ul>
              </div>
              <div className='model__auth-group'>
                <button
                  className={`modal__form-btn is--auth ${(oAuthCompleted && oAuthSuccess && oAuthToken) ? 'is--authenticated' : ''}`}
                  type="button"
                  onClick={initiateFacebookAdsOAuthFlow}
                  disabled={(oAuthCompleted && oAuthSuccess && oAuthToken) || oAuthInProgress}
                >
                  {(oAuthCompleted && oAuthSuccess && oAuthToken) ? <FaCheckCircle style={{marginRight: '5px'}} size={17} color='#fff'/> : ''}
                  {(oAuthCompleted && oAuthSuccess && oAuthToken) ? t('connectionAndChannel.authenticated') :t('connectionAndChannel.authorize_access')}
                  {oAuthInProgress && <Loader 
                      size={17}
                      color='#fff'
                      containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
                  />}
                </button>
              </div>
              <div className="modal__form-group">
                <span className="modal__form-label">{t('connectionAndChannel.provide_id')}:</span>
                <input
                  type="text"
                  className="modal__form-input is--text"
                  placeholder={t('connectionAndChannel.ad_account_id')}
                  onChange={(ev) => setFaId(ev.target.value)}
                  disabled={!(oAuthCompleted && oAuthSuccess && oAuthToken) || savingInProgress}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="modal__footer">
          <button
            className="modal__form-btn is--cancel"
            type="button"
            onClick={handleCancel}
            disabled={savingInProgress}
          >
            {t('common.cancel')}
          </button>
          <button
            className="modal__form-btn is--save"
            type="button"
            onClick={handleFaSaveConfig}
            disabled={!(oAuthCompleted && oAuthSuccess && oAuthToken) || savingInProgress}
          >
            {t('common.save')}
            {savingInProgress && <Loader 
                size={17}
                color='#fff'
                containerStyles={{width: "auto", height: "auto", marginLeft: 5}}
            />}
          </button>
        </div>
      </div>
    </S.CustomModalFacebookAds>
  );
}

export default CustomModalFacebookAds;
