import React, { useEffect, useCallback, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import CustomTableApiPagination from '../../components/CustomTableApiPagination';
import { defaultChannelsOptions } from '../../options/filterOptions';

import { 
    getSelectedCustomerBehaviorInitialDateRange, 
    formatDate,
    setCurrentCustomerBehaviorDateRange
} from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber } from '../../utils/metricsUtils';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import { 
    getCustomerBehavior,
    sendReportToEmailCustomerBehavior 
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const CustomerBehavior = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const DEFAULT_ROWS_PER_PAGE = 100;
    const DEFAULT_PAGE = 1;
    const MAX_DATE_RANGE_IN_DAYS = 183; // 6 meses

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [statesOptions, setStatesOptions] = useState([]);
    const [customerFrequenciesOptions, setCustomerFrequenciesOptions] = useState([]);
    const [customerRecenciesOptions, setCustomerRecenciesOptions] = useState([]);
    const [customerDecilesOptions, setCustomerDecilesOptions] = useState([]);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [acquisitionChannelOptions, setAcquisitionChannelOptions] = useState([]);
    const [selectedCitiesOptions, setSelectedCitiesOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [selectedAcquisitionChannelOptions, setSelectedAcquisitionChannelOptions] = useState([]);
    const [selectedCustomerFrequenciesOptions, setSelectedCustomerFrequenciesOptions] = useState([]);
    const [selectedCustomerRecenciesOptions, setSelectedCustomerRecenciesOptions] = useState([]);
    const [selectedCustomerDecilesOptions, setSelectedCustomerDecilesOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);

    const [selectedProductSkuIdsOptions, setSelectedProductSkuIdsOptions] = useState([]);
    const [selectedProductSkuNamesOptions, setSelectedProductSkuNamesOptions] = useState([]);
    const [selectedProductIdsOptions, setSelectedProductIdsOptions] = useState([]);
    const [selectedProductNamesOptions, setSelectedProductNamesOptions] = useState([]);
    const [productDepartmentsOptions, setProductDepartmentsOptions] = useState([]);
    const [selectedProductDepartmentsOptions, setSelectedProductDepartmentsOptions] = useState([]);
    const [productCategoriesOptions, setProductCategoriesOptions] = useState([]);
    const [selectedProductCategoriesOptions, setSelectedProductCategoriesOptions] = useState([]);
    const [productSubCategoriesOptions, setProductSubCategoriesOptions] = useState([]);
    const [selectedProductSubCategoriesOptions, setSelectedProductSubCategoriesOptions] = useState([]);
    
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [exportReportLoading, setExportReportLoading] = useState(false);

    const [customerBehaviorTableFullScreen, setCustomerBehaviorFullScreen] = useState(false);

    const location = useLocation();

    dayjs.extend(customParseFormat);

    const getSegmentationFilterCustomLabel = (values) => {
        const labels =   {
            "R1": t('rfv.r1_last_30_days'),
            "R2": t('rfv.r2_31_to_60_days'),
            "R3": t('rfv.r3_61_to_120_days'),
            "R4": t('rfv.r4_121_to_180_days'),
            "R5": t('rfv.r5_181_to_360_days'),
            "R6": t('rfv.r6_more_than_360_days'),
            "F1": t('rfv.f1_12_or_more_purchases'),
            "F2": t('rfv.f2_7_to_11_purchases'),
            "F3": t('rfv.f3_4_to_6_purchases'),
            "F4": t('rfv.f4_2_to_3_purchases'),
            "F5": t('rfv.f5_1_purchase'),
        }

        return values.map((value) => value in labels ? labels[value] : value);
    };

    const getSegmentationFilterValueByCustomLabel = useCallback((values) => {
        const labels = {
            [t('rfv.r1_last_30_days')]: "R1",
            [t('rfv.r2_31_to_60_days')]: "R2",
            [t('rfv.r3_61_to_120_days')]: "R3",
            [t('rfv.r4_121_to_180_days')]: "R4",
            [t('rfv.r5_181_to_360_days')]: "R5",
            [t('rfv.r6_more_than_360_days')]: "R6",
            [t('rfv.f1_12_or_more_purchases')]: "F1",
            [t('rfv.f2_7_to_11_purchases')]: "F2",
            [t('rfv.f3_4_to_6_purchases')]: "F3",
            [t('rfv.f4_2_to_3_purchases')]: "F4",
            [t('rfv.f5_1_purchase')]: "F5",
        }
    
        return values.map((value) => value in labels ? labels[value] : value);
    }, [t]);

    const getSegmentationFilterByUrlParams = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);

        const segmentation_recency = searchParams.get('segmentation_rx');
        const segmentation_frequency = searchParams.get('segmentation_fx');

        return {
            segmentation_recencies: segmentation_recency ? getSegmentationFilterValueByCustomLabel([segmentation_recency]) : null,
            segmentation_frequencies: segmentation_frequency ? getSegmentationFilterValueByCustomLabel([segmentation_frequency]) : null
        }
    }, [location, getSegmentationFilterValueByCustomLabel]);

    const getActiveFilters = () => {
        const [startDate, endDate] = dateRange;
        const productSkuIds = selectedProductSkuIdsOptions ? selectedProductSkuIdsOptions.map(sku => sku.value) : null;
        const productSkuNames = selectedProductSkuNamesOptions? selectedProductSkuNamesOptions.map(skuName => skuName.value) : null;
        const productIds = selectedProductIdsOptions ? selectedProductIdsOptions.map(productId => productId.value) : null;
        const productNames = selectedProductNamesOptions ? selectedProductNamesOptions.map(productName => productName.value) : null;
        const productDepartments = selectedProductDepartmentsOptions.length > 0 ? [...selectedProductDepartmentsOptions] : null;
        const productCategories = selectedProductCategoriesOptions.length > 0 ? [...selectedProductCategoriesOptions] : null;
        const productSubcategories = selectedProductSubCategoriesOptions.length > 0 ? [...selectedProductSubCategoriesOptions] : null;
        const cities = selectedCitiesOptions.length > 0 ? [...selectedCitiesOptions] : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const customerFrequencies = selectedCustomerFrequenciesOptions.length > 0 ? [...selectedCustomerFrequenciesOptions] : null;
        const customerRecencies = selectedCustomerRecenciesOptions.length > 0 ? [...selectedCustomerRecenciesOptions] : null;
        const customerDeciles = selectedCustomerDecilesOptions.length > 0 ? [...selectedCustomerDecilesOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;
        const channels = selectedAcquisitionChannelOptions.length > 0 
        ? defaultChannelsOptions[0].options
            .filter(option => selectedAcquisitionChannelOptions.includes(option.label))
            .map(option => option.value)
        : null  


        const filter = {
            initialDate: formatDate(startDate),
            finalDate: formatDate(endDate),
            timezone,
            cities,
            states,
            customerFrequencies,
            customerRecencies,
            customerDeciles,
            origins,
            salesChannels,
            vtexAffiliates,
            productSkuIds,
            productSkuNames,
            productIds,
            productNames,
            productDepartments,
            productCategories,
            productSubcategories,
            channels
        }

        return filter;
    };

    const handleApplyFilter = async (page) => {
        const filter = getActiveFilters()

        await getDataMetrics(filter, page);
    }

    const getInitialDateAndFinalDateByRecency = (recency) => {
        const today = dayjs(new Date());
    
        switch (recency) {
            case "R1":
                return {
                    initialDate: today.subtract(30, 'day'),
                    finalDate: today
                };
            case "R2":
                return {
                    initialDate: today.subtract(60, 'day'),
                    finalDate: today.subtract(31, 'day')
                };
            case "R3":
                return {
                    initialDate: today.subtract(120, 'day'),
                    finalDate: today.subtract(61, 'day')
                };
            case "R4":
                return {
                    initialDate: today.subtract(180, 'day'),
                    finalDate: today.subtract(121, 'day')
                };
            case "R5":
                return {
                    initialDate: today.subtract(360, 'day'),
                    finalDate: today.subtract(181, 'day')
                };
            case "R6":
                return {
                    initialDate: today.subtract(361, 'day').subtract(MAX_DATE_RANGE_IN_DAYS, 'day'),
                    finalDate: today.subtract(361, 'day')
                };
            default:
                return {
                    initialDate: today,
                    finalDate: today
                };    
        }
    }

    const sortSegmentationFilterOptions = (options) => {
        return options.sort((a, b) => {
            const numA = parseInt(a.match(/\d+/), 10);
            const numB = parseInt(b.match(/\d+/), 10);
        
            if (!isNaN(numA) && !isNaN(numB)) {
            if (numA < numB) {
                return -1;
            } else if (numA > numB) {
                return 1;
            } else {
                return a.localeCompare(b, undefined, { numeric: true });
            }
            } else {
            return a.localeCompare(b, undefined, { numeric: true });
            }
        })
    };

    const sortFilterOptions = (options) => {
        return options.sort((a, b) => a > b ? 1 : -1);
    }

    const getAcquisitionChannelOptions = (selectedChannels) => {
        if (selectedChannels && selectedChannels.length > 0)
            return defaultChannelsOptions[0].options
                .filter(option => selectedChannels.includes(option.value))
                .map(option => option.label)
        else
            return defaultChannelsOptions[0].options.map(option => option.label);
    }

    const getDataMetrics = useCallback(async (filter) => {
        setIsLoading(true);
  
        try {
            const response = await getCustomerBehavior(filter, DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE);

            if (!response) {
                setData([])
                setCitiesOptions([]);
                setStatesOptions([]);
                setCustomerFrequenciesOptions([]);
                setCustomerRecenciesOptions([]);
                setCustomerDecilesOptions([]);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);
                setVtexAffiliatesOptions([]);
                setProductDepartmentsOptions([]);
                setProductSubCategoriesOptions([]);
                setAcquisitionChannelOptions([]);
                setTotalItems(0);

                return;
            }

            setData(response.results)
            setCitiesOptions(sortFilterOptions(response.available_cities_to_filter));
            setStatesOptions(sortFilterOptions(response.available_states_to_filter));
            setProductCategoriesOptions(sortSegmentationFilterOptions(response.available_categories_to_filter));
            setProductSubCategoriesOptions(sortSegmentationFilterOptions(response.available_subcategories_to_filter));
            setProductDepartmentsOptions(sortSegmentationFilterOptions(response.available_departments_to_filter));
            setCustomerFrequenciesOptions(sortSegmentationFilterOptions(response.available_customer_frequencies_to_filter));
            setCustomerRecenciesOptions(sortSegmentationFilterOptions(response.available_customer_recencies_to_filter));
            setCustomerDecilesOptions(sortSegmentationFilterOptions(response.available_customer_deciles_to_filter));
            setOriginsOptions(response.available_origins_to_filter);
            setSalesChannelsOptions(response.available_marketplaces_to_filter);
            setAcquisitionChannelOptions(getAcquisitionChannelOptions(filter.channels));
            
            if (isValidAvailableFilter(response.available_affiliates_to_filter)) {
                setVtexAffiliatesOptions(response.available_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }

            setTotalItems(response.pagination.total_items);
        } catch (error) {
            toast.error(error.message);
            setData([])
            setCitiesOptions([]);
            setStatesOptions([]);
            setProductDepartmentsOptions([]);
            setProductSubCategoriesOptions([]);
            setCustomerFrequenciesOptions([]);
            setCustomerRecenciesOptions([]);
            setCustomerDecilesOptions([]);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
            setVtexAffiliatesOptions([]);
            setAcquisitionChannelOptions([]);
            setTotalItems(0);
        } finally {
          setIsLoading(false);
        }
    }, []);

    const handleExportReport = async () => {
        setExportReportLoading(true);
        try {
            toast.info(t('toast.wait_for_report'))
            
            const filter = getActiveFilters()

            await sendReportToEmailCustomerBehavior(filter, DEFAULT_PAGE );

            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    }

    const getFormattedData = (data) => {
        return {
            headers: [
                {name: t('common.date'), value: "date", minWidth: 167, format: (value) => value},
                {name: t('customerBehavior.order_id'), value: "order_id", minWidth: 160, format: (value) => value},
                {name: t('productPerformance.product_id'), value: "product_id", minWidth: 100, format: (value) => value},
                {name: t('productPerformance.product_name'), value: "product_name", minWidth: 300, format: (value) => value},
                {name: t('productPerformance.product_sku_id'), value: "product_sku_id", minWidth: 100, format: (value) => value},
                {name: t('productPerformance.product_sku_name'), value: "product_sku_name", minWidth: 300, format: (value) => value},
                {name: t('productBehaviorCurveAbc.department'), value: "product_department", minWidth: 120, format: (value) => value},
                {name: t('productPerformance.category'), value: "product_category", minWidth: 120, format: (value) => value},
                {name: t('productBehaviorCurveAbc.subcategory'), value: "product_sub_category", minWidth: 120, format: (value) => value},
                {name: t('customerBehavior.brand'), value: "product_brand", minWidth: 120, format: (value) => value},
                {name: t('common.approved_revenue'), value: "product_total_value", minWidth: 145, format: value => formatValueToMoney(value, currency)},
                {name: t('customerBehavior.quantity'), value: "product_total_quantity", minWidth: 100, format: formatValueToNumber},
                {name: t('customerBehavior.customer_name'), value: "customer_full_name", minWidth: 150, format: (value) => value},
                {name: t('customerBehavior.customer_email'), value: "customer_email", minWidth: 150, format: (value) => value},
                {name: t('customerBehavior.customer_phone'), value: "customer_phone", minWidth: 165, format: (value) => value},
                {name: t('common.city'), value: "customer_address_city", minWidth: 130, format: (value) => value},
                {name: t('common.state'), value: "customer_address_state", minWidth: 100, format: (value) => value},
                {name: t('customerBehavior.coupon'), value: "coupon", minWidth: 100, format: (value) => value},
                {name: t('menu.decile'), value: "customer_dx", minWidth: 50, format: (value) => value},
                {name: t('rfv.frequency'), value: "customer_fx", minWidth: 150, format: (value) => value},
                {name: t('customerBehavior.recency'), value: "customer_rx", minWidth: 150, format: (value) => value},
                {name: `${t('common.source')}`, value: "origin", minWidth: 100, format: (value) => value},
                {name: t('common.sales_channel'), value: "marketplace_channel", minWidth: 100, format: (value) => value},
                {name: t('common.affiliates_vtex'), value: "affiliate", minWidth: 100, format: (value) => value},
                {name: t('customerBehavior.platform'), value: "source_platform", minWidth: 50, format: (value) => value},
            ],
            rows: data
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: t('productPerformance.product_sku_id'),
                value: selectedProductSkuIdsOptions,
                setValue:  setSelectedProductSkuIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_sku_id')
            },
            {
                title: t('productPerformance.product_sku_name'),
                value: selectedProductSkuNamesOptions,
                setValue: setSelectedProductSkuNamesOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_shirt'),
                tooltipInformationValue: t('productPerformance.search_by_keywords')
            },
            {
                title: t('productPerformance.product_id'),
                value: selectedProductIdsOptions,
                setValue: setSelectedProductIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_id')
            },
            {
                title: t('productPerformance.product_name'),
                value: selectedProductNamesOptions,
                setValue: setSelectedProductNamesOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_shirt'),
                tooltipInformationValue: t('productPerformance.search_by_keywords')
            },
            {
                title: t('productPerformance.departments'),
                options: productDepartmentsOptions,
                value: selectedProductDepartmentsOptions,
                setValue: setSelectedProductDepartmentsOptions
            },
            {
                title: t('productPerformance.categories'),
                options: productCategoriesOptions,
                value: selectedProductCategoriesOptions,
                setValue: setSelectedProductCategoriesOptions
            },
            {
                title: t('productPerformance.subcategories'),
                options: productSubCategoriesOptions,
                value: selectedProductSubCategoriesOptions,
                setValue: setSelectedProductSubCategoriesOptions
            },
            {
                title: t('common.acquisition_channel'),
                options: acquisitionChannelOptions,
                value: selectedAcquisitionChannelOptions,
                setValue: setSelectedAcquisitionChannelOptions,
            }, 
            {
                title: t('logisticShippingMethodEvolution.cities'),
                options: citiesOptions,
                value: selectedCitiesOptions,
                setValue: setSelectedCitiesOptions
            },
            {
                title: `${t('common.states')}`,
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions
            },
            {
                title: t('menu.decile'),
                options: customerDecilesOptions,
                value: selectedCustomerDecilesOptions,
                setValue: setSelectedCustomerDecilesOptions
            },
            {
                title: t('rfv.frequency'),
                options: getSegmentationFilterCustomLabel(customerFrequenciesOptions),
                value: getSegmentationFilterCustomLabel(selectedCustomerFrequenciesOptions),
                setValue: (values) => setSelectedCustomerFrequenciesOptions(getSegmentationFilterValueByCustomLabel(values))
            },
            {
                title: t('customerBehavior.recency'),
                options: getSegmentationFilterCustomLabel(customerRecenciesOptions),
                value: getSegmentationFilterCustomLabel(selectedCustomerRecenciesOptions),
                setValue: (values) => setSelectedCustomerRecenciesOptions(getSegmentationFilterValueByCustomLabel(values))
            },
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: vtexAffiliatesOptions,
                value: selectedVtexAffiliatesOptions,
                setValue: setSelectedVtexAffiliatesOptions
            },
            
        ]
    }

    const onRenderComponent = useCallback(async () => {
        let { initialDateFilter, finalDateFilter } = getSelectedCustomerBehaviorInitialDateRange();
        const segmentationFilterByUrlParams = getSegmentationFilterByUrlParams();
    
        if (segmentationFilterByUrlParams.segmentation_recencies) {
            const recencyRangeDate = getInitialDateAndFinalDateByRecency(segmentationFilterByUrlParams.segmentation_recencies[0]);
            initialDateFilter = recencyRangeDate.initialDate;
            finalDateFilter = recencyRangeDate.finalDate;
    
            setSelectedCustomerFrequenciesOptions(segmentationFilterByUrlParams.segmentation_frequencies);
            setSelectedCustomerRecenciesOptions(segmentationFilterByUrlParams.segmentation_recencies);
        }
    
        setDateRange([initialDateFilter, finalDateFilter]);
    
        await getDataMetrics({
            initialDate: formatDate(initialDateFilter),
            finalDate: formatDate(finalDateFilter),
            segmentationFrequencies: segmentationFilterByUrlParams.segmentation_frequencies,
            segmentationRecencies: segmentationFilterByUrlParams.segmentation_recencies
        });
    }, [getDataMetrics, getSegmentationFilterByUrlParams]);
    
    useEffect(() => {
        onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew 
          titlePage={t('menu.customer_behavior')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
                maxDaysToFilterInDateRange={MAX_DATE_RANGE_IN_DAYS}
                showExportReportButton={true}
                handleExportReport={handleExportReport}
                disabledExportReportButton={data.length === 0 || isLoading || exportReportLoading}
                loadingExportReportButton={exportReportLoading}
                setCurrentDateRangeCustomized={setCurrentCustomerBehaviorDateRange}
            />
            <CardGroup
                title={t('customerBehavior.customer_segmentation')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <CustomTableApiPagination
                    data={getFormattedData(data)}
                    disabledDimensionController={data.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={customerBehaviorTableFullScreen}
                    setFullScreenEnabled={setCustomerBehaviorFullScreen}
                    totalItems={totalItems}
                    showPagination={false}
                    showTableFooter={true}
                    tableFooterDescription={`${t('customerBehavior.table_notice_part1')} ${DEFAULT_ROWS_PER_PAGE} ${t('customerBehavior.table_notice_part2')}: ${totalItems}`}
                />
            </CardGroup>
        </BackgroundNew>
    );
}

export default CustomerBehavior;