import React, { useEffect, useCallback, useState, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import CardIndicator from '../../components/CardIndicator';
import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import CustomTableApiPagination from '../../components/CustomTableApiPagination';
import { ImArrowUp, ImArrowDown } from 'react-icons/im';
import CustomCategoryTable from './CustomCategoryTable';
import { defaultChannelsOptions } from '../../options/filterOptions';

import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';
import { formatValueToMoney, formatValueToNumber, formatValueToPercentage } from '../../utils/metricsUtils';
import { isValidAvailableFilter } from '../../utils/validationUtils';

import {
  getProductPerformance,
  getCategoriesPerformance,
  sendReportToEmailProductPerformance, sendReportToEmailCategoriesPerformance,
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const ProductPerformance = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const DEFAULT_ROWS_PER_PAGE = 100;
    const DEFAULT_PAGE = 1;

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoadingTablePagination, setIsLoadingTablePagination] = useState(false);

    const [selectedProductSkuIdsOptions, setSelectedProductSkuIdsOptions] = useState([]);
    const [selectedProductSkuNamesOptions, setSelectedProductSkuNamesOptions] = useState([]);
    const [selectedProductIdsOptions, setSelectedProductIdsOptions] = useState([]);
    const [selectedProductNamesOptions, setSelectedProductNamesOptions] = useState([]);
    const [productDepartmentsOptions, setProductDepartmentsOptions] = useState([]);
    const [selectedProductDepartmentsOptions, setSelectedProductDepartmentsOptions] = useState([]);
    const [productCategoriesOptions, setProductCategoriesOptions] = useState([]);
    const [selectedProductCategoriesOptions, setSelectedProductCategoriesOptions] = useState([]);
    const [productSubCategoriesOptions, setProductSubCategoriesOptions] = useState([]);
    const [selectedProductSubCategoriesOptions, setSelectedProductSubCategoriesOptions] = useState([]);
    const [selectedMinProductStockOptions, setSelectedMinProductStockOptions] = useState('');
    const [selectedMaxProductStockOptions, setSelectedMaxProductStockOptions] = useState('');
    const [weekDaysOptions, setWeekDaysOptions] = useState([]);
    const [selectedWeekDaysOptions, setSelectedWeekDaysOptions] = useState([]);
    const [couponsOptions, setCouponsOptions] = useState([]);
    const [selectedCouponsOptions, setSelectedCouponsOptions] = useState([]);
    const [originsOptions, setOriginsOptions] = useState([]);
    const [selectedOriginsOptions, setSelectedOriginsOptions] = useState([]);
    const [salesChannelsOptions, setSalesChannelsOptions] = useState([]);
    const [selectedSalesChannelsOptions, setSelectedSalesChannelsOptions] = useState([]);
    const [vtexAffiliatesOptions, setVtexAffiliatesOptions] = useState([]);
    const [selectedVtexAffiliatesOptions, setSelectedVtexAffiliatesOptions] = useState([]);
    const [vtexWarehousesIdsOptions, setVtexWarehousesIdsOptions] = useState([]);
    const [selectedVtexWarehousesIdsOptions, setSelectedVtexWarehousesIdsOptions] = useState([]);
    const [smartClassificationsOptions, setSmartClassificationsOptions] = useState([]);
    const [selectedSmartClassificationsOptions, setSelectedSmartClassificationsOptions] = useState([]);
    const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
    const [statesOptions, setStatesOptions] = useState([]);
    const [selectedProductBrandsOptions, setSelectedProductBrandsOptions] = useState([]);
    const [productBrandsOptions, setProductBrandsOptions] = useState([]);
    const [acquisitionChannelOptions, setAcquisitionChannelOptions] = useState([]);
    const [selectedAcquisitionChannelOptions, setSelectedAcquisitionChannelOptions] = useState([]);

    const [productsData, setProductsData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [bigNumbers, setBigNumbers] = useState(null);

    const [exportReportLoading, setExportReportLoading] = useState(false);
    const [productPerformanceTableFullScreen, setProductPerformanceFullScreen] = useState(false);
    const [categoriesPerformanceTableFullScreen, setCategoriesPerformanceFullScreen] = useState(false);

    const getDataByAppliedFilter = async (page) => {
        const filter = getActiveFilters()

        if (!validateMinMaxStockQuantity(filter.minProductStockQuantity, filter.maxProductStockQuantity)) {
            return;
        }

        await getDataMetrics(filter, page);
    };

    const getActiveFilters = () => {
        const [startDate, endDate] = dateRange;
        const productSkuIds = selectedProductSkuIdsOptions ? selectedProductSkuIdsOptions.map(sku => sku.value) : null;
        const productSkuNames = selectedProductSkuNamesOptions? selectedProductSkuNamesOptions.map(skuName => skuName.value) : null;
        const productIds = selectedProductIdsOptions ? selectedProductIdsOptions.map(productId => productId.value) : null;
        const productNames = selectedProductNamesOptions ? selectedProductNamesOptions.map(productName => productName.value) : null;
        const productDepartments = selectedProductDepartmentsOptions.length > 0 ? [...selectedProductDepartmentsOptions] : null;
        const productCategories = selectedProductCategoriesOptions.length > 0 ? [...selectedProductCategoriesOptions] : null;
        const productSubcategories = selectedProductSubCategoriesOptions.length > 0 ? [...selectedProductSubCategoriesOptions] : null;
        const minProductStockQuantity = selectedMinProductStockOptions !== '' ? parseInt(selectedMinProductStockOptions) : null;
        const maxProductStockQuantity = selectedMaxProductStockOptions !== '' ? parseInt(selectedMaxProductStockOptions) : null;
        const weekDays = selectedWeekDaysOptions.length > 0 ? [...selectedWeekDaysOptions] : null;
        const coupons = selectedCouponsOptions.length > 0 ? [...selectedCouponsOptions] : null;
        const origins = selectedOriginsOptions.length > 0 ? [...selectedOriginsOptions] : null;
        const salesChannels = selectedSalesChannelsOptions.length > 0 ? [...selectedSalesChannelsOptions] : null;
        const vtexAffiliates = selectedVtexAffiliatesOptions.length > 0 ? [...selectedVtexAffiliatesOptions] : null;
        const vtexWarehouseIds = selectedVtexWarehousesIdsOptions.length > 0 ? [...selectedVtexWarehousesIdsOptions] : null;
        const smartClassifications = selectedSmartClassificationsOptions.length > 0 ? [...selectedSmartClassificationsOptions] : null;
        const states = selectedStatesOptions.length > 0 ? [...selectedStatesOptions] : null;
        const productBrands = selectedProductBrandsOptions.length > 0 ? [...selectedProductBrandsOptions] : null;
        
        const channels = selectedAcquisitionChannelOptions.length > 0 
        ? defaultChannelsOptions[0].options
            .filter(option => selectedAcquisitionChannelOptions.includes(option.label))
            .map(option => option.value)
        : null


        const filter = {
            initialDate: formatDate(startDate),
            finalDate: formatDate(endDate),
            timezone,
            productSkuIds,
            productSkuNames,
            productIds,
            productNames,
            productDepartments,
            productCategories,
            productSubcategories,
            minProductStockQuantity,
            maxProductStockQuantity,
            weekDays,
            coupons,
            origins,
            salesChannels,
            vtexAffiliates,
            vtexWarehouseIds,
            smartClassifications,
            states,
            productBrands,
            channels,
        }

        return filter;
    }

    const handleChangePage = async (page) => {
          setCurrentPage(page);

          const filter = getActiveFilters()

          if (!validateMinMaxStockQuantity(filter.minProductStockQuantity, filter.maxProductStockQuantity)) {
              return;
          }

          await getProductMetricsByPage(filter, page);
      }

    const handleApplyFilter = async () => {
          setCurrentPage(DEFAULT_PAGE);
          getDataByAppliedFilter();
      }

    const getWeekDaysFilterCustomLabel = (values) => {
        const labels = {
            1: t('common.sunday'),
            2: t('common.monday'),
            3: t('common.tuesday'),
            4: t('common.wednesday'),
            5: t('common.thursday'),
            6: t('common.friday'),
            7: t('common.saturday')
        };

        return values.map((value) => value in labels ? labels[value] : value);
    };

    const getWeekDaysFilterValueByCustomLabel = (values) => {
        const labels = {
            [t('common.sunday')]: 1,
            [t('common.monday')]: 2,
            [t('common.tuesday')]: 3,
            [t('common.wednesday')]: 4,
            [t('common.thursday')]: 5,
            [t('common.friday')]: 6,
            [t('common.saturday')]: 7
        };

        return values.map((value) => value in labels ? labels[value] : value);
    }

    const sortFilterOptions = (options) => {
        return options.sort((a, b) => a > b ? 1 : -1);
    }

    const validateMinMaxStockQuantity = (minProductStockQuantity, maxProductStockQuantity) => {
        if (!minProductStockQuantity && !maxProductStockQuantity) {
            return true;
        }

        if (minProductStockQuantity === null && maxProductStockQuantity !== null) {
            toast.warning(t('toast.stock_filter_min_empty'));
            return false;
        }

        if (minProductStockQuantity !== null && maxProductStockQuantity === null) {
            toast.warning(t('toast.stock_filter_max_empty'));
            return false;
        }

        if (minProductStockQuantity >= 0 && maxProductStockQuantity >= 0 && minProductStockQuantity > maxProductStockQuantity) {
            toast.warning(t('toast.stock_filter_min_max_error'));
            return false;
        }

        return true;
    }

    const defineComparativeValueColor = (value) => {
        if (value > 0) {
            return 'green'
        } else if (value < 0) {
            return 'red'
        }
    }

    const formatComparativeValue = (value) => {
        if (value > 0) {
            return <span>{formatValueToPercentage(value)}<ImArrowUp size={15}/></span>
        } else if (value < 0) {
            return <span style={{display: 'flex', alignItems: "center"}}>{formatValueToPercentage(value)}<ImArrowDown size={15}/></span>
        } else {
            return formatValueToPercentage(value)
        }
    }

    const getAcquisitionChannelOptions = (selectedChannels) => {
        if (selectedChannels && selectedChannels.length > 0)
            return defaultChannelsOptions[0].options
                .filter(option => selectedChannels.includes(option.value))
                .map(option => option.label)
        else
            return defaultChannelsOptions[0].options.map(option => option.label);
    }

    const getProductMetricsByPage = useCallback(async (filter, page) => {
        setIsLoadingTablePagination(true);
        try {
            const response = await getProductPerformance(filter, page, DEFAULT_ROWS_PER_PAGE);

            setProductsData(response.results)
            setTotalItems(response.pagination.total_items);
            setCurrentPage(response.pagination.current_page);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoadingTablePagination(false);
        }
    }, []);

    const getDataMetrics = useCallback(async (filter) => {
        setIsLoading(true);

        try {
            const productPerformaceResponse = await getProductPerformance(filter, DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE);
            const categoriesPerformanceResponse = await getCategoriesPerformance(filter);

            if (!productPerformaceResponse || !categoriesPerformanceResponse) {
                setProductsData([])
                setProductDepartmentsOptions([]);
                setProductCategoriesOptions([]);
                setProductSubCategoriesOptions([]);
                setWeekDaysOptions([]);
                setCouponsOptions([]);
                setOriginsOptions([]);
                setSalesChannelsOptions([]);
                setVtexAffiliatesOptions([]);
                setVtexWarehousesIdsOptions([]);
                setSmartClassificationsOptions([]);
                setTotalItems(0);
                setStatesOptions([]);
                setProductBrandsOptions([]);
                setAcquisitionChannelOptions([]);

                return;
            }

            setProductsData(productPerformaceResponse.results)
            setBigNumbers(productPerformaceResponse.big_numbers);
            setCategoriesData(categoriesPerformanceResponse.results);

            setProductDepartmentsOptions(sortFilterOptions(productPerformaceResponse.available_product_departments_to_filter));
            setProductCategoriesOptions(sortFilterOptions(productPerformaceResponse.available_product_categories_to_filter));
            setProductSubCategoriesOptions(sortFilterOptions(productPerformaceResponse.available_product_sub_categories_to_filter));
            setWeekDaysOptions(sortFilterOptions(productPerformaceResponse.available_week_days_to_filter));
            setCouponsOptions(productPerformaceResponse.available_coupons_to_filter);
            setOriginsOptions(productPerformaceResponse.available_origins_to_filter);
            setSalesChannelsOptions(productPerformaceResponse.available_marketplace_channels_to_filter);
            setSmartClassificationsOptions(productPerformaceResponse.available_smart_classifications_to_filter);
            setStatesOptions(productPerformaceResponse.available_states_to_filter);
            setProductBrandsOptions(productPerformaceResponse.available_product_brands_to_filter);
            setAcquisitionChannelOptions(getAcquisitionChannelOptions(filter.channels));

            if (isValidAvailableFilter(productPerformaceResponse.available_warehouse_ids_to_filter)) {
                setVtexWarehousesIdsOptions(productPerformaceResponse.available_warehouse_ids_to_filter);
            } else {
                setVtexWarehousesIdsOptions([]);
            }

            if (isValidAvailableFilter(productPerformaceResponse.available_vtex_affiliates_to_filter)) {
                setVtexAffiliatesOptions(productPerformaceResponse.available_vtex_affiliates_to_filter);
            } else {
                setVtexAffiliatesOptions([])
            }

            setTotalItems(productPerformaceResponse.pagination.total_items);
            setCurrentPage(productPerformaceResponse.pagination.current_page);
        } catch (error) {
            toast.error(error.message);
            setProductsData([])
            setProductDepartmentsOptions([]);
            setProductCategoriesOptions([]);
            setProductSubCategoriesOptions([]);
            setWeekDaysOptions([]);
            setCouponsOptions([]);
            setOriginsOptions([]);
            setSalesChannelsOptions([]);
            setVtexAffiliatesOptions([]);
            setVtexWarehousesIdsOptions([]);
            setSmartClassificationsOptions([]);
            setStatesOptions([]);
            setProductBrandsOptions([]);
            setAcquisitionChannelOptions([]);
            setTotalItems(0);
        } finally {
          setIsLoading(false);
        }
    }, []);

    const handleExportReport = async (reportType) => {
        setExportReportLoading(true);

        try {
          toast.info(t('toast.wait_for_report'));

          const filter = getActiveFilters()

          if (reportType === 'product')
            await sendReportToEmailProductPerformance(filter);

          if (reportType === 'category')
            await sendReportToEmailCategoriesPerformance(filter)

          toast.success(t('toast.report_preparation'));
        } catch (error) {
          toast.error(t('toast.report_generation_failed'));
        } finally {
          setExportReportLoading(false);
        }
    };

    const getFormattedData = (data) => {
        return {
            headers: [
                {name: t('productPerformance.photo'), value: "product_image_url", minWidth: 167, format: (value) => <img style={{width: "70px", height: "70px", borderRadius: "5px"}} src={value} alt="Foto do Produto"/>},
                {name: t('productPerformance.product_name'), value: "product_name", minWidth: 300, format: (value) => value, stickyCell: true},
                {name: t('common.approved_revenue'), value: "product_approved_revenue", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('productPerformance.approved_revenue_previous_period_percent'), value: "comparative_product_approved_revenue_previous_period", minWidth: 170, defineColor: defineComparativeValueColor, format: formatComparativeValue},
                {name: t('productPerformance.approved_revenue_previous_year_percent'), value: "comparative_product_approved_revenue_previous_year", minWidth: 170, defineColor: defineComparativeValueColor, format: formatComparativeValue},
                {name: t('productPerformance.representativeness'), value: "product_approved_revenue_share", minWidth: 100, format: formatValueToPercentage},
                {name: t('productPerformance.quantity_sold'), value: "product_sold_quantity", minWidth: 100, format: formatValueToNumber},
                {name: t('productPerformance.quantity_sold_without_discount_percent'), value: "product_sold_without_discount_percentage", minWidth: 155, format: formatValueToPercentage},
                {name: t('productPerformance.quantity_sold_with_discount_percent'), value: "product_sold_with_discount_percentage", minWidth: 155, format: formatValueToPercentage},
                {name: t('productPerformance.product_price'), value: "product_price", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('productPerformance.product_cost'), value: "product_cost", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('productPerformance.product_total_cost'), value: "product_total_cost", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('productPerformance.stock_coverage_in_days'), value: "product_stock_coverage_days", minWidth: 150, format: formatValueToNumber},
                {name: t('productPerformance.stock'), value: "product_stock_quantity", minWidth: 100, format: (value) => value},
                {name: t('productPerformance.reserved_stock'), value: "product_stock_reserved_quantity", minWidth: 100, format: (value) => value},
                {name: t('common.sessions'), value: "product_sessions", minWidth: 100, format: formatValueToNumber},
                {name: t('common.average_ticket'), value: "product_average_ticket", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('common.conversion_rate'), value: "product_conversion_rate", minWidth: 100, format: formatValueToPercentage},
                {name: t('productPerformance.margin'), value: "product_margin_percentage", minWidth: 100, format: formatValueToPercentage},
                {name: t('productPerformance.total_freight'), value: "product_total_shipping_price", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('productPerformance.average_freight'), value: "product_average_shipping_price", minWidth: 100, format: value => formatValueToMoney(value, currency)},
                {name: t('productPerformance.smart_classification'), value: "smart_classification", minWidth: 200, format: (value) => value},
                {name: t('productPerformance.product_id'), value: "product_id", minWidth: 100, format: (value) => value},
                {name: t('productPerformance.product_sku_name'), value: "product_sku_name", minWidth: 300, format: (value) => value},
                {name: t('productPerformance.product_sku_id'), value: "product_sku_id", minWidth: 100, format: (value) => value},
                {name: t('productBehaviorCurveAbc.department'), value: "product_department_name", minWidth: 100, format: (value) => value},
                {name: t('productPerformance.category'), value: "product_category_name", minWidth: 100, format: (value) => value},
                {name: t('productBehaviorCurveAbc.subcategory'), value: "product_sub_category_name", minWidth: 100, format: (value) => value},
                {name: t('productPerformance.product_brand'), value: "product_brand", minWidth: 100, format: (value) => value},
            ],
            rows: data
        }
    }

    const getSecondaryFilters = () => {
        return [
            {
                title: t('productPerformance.product_sku_id'),
                value: selectedProductSkuIdsOptions,
                setValue:  setSelectedProductSkuIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_sku_id')
            },
            {
                title: t('productPerformance.product_sku_name'),
                value: selectedProductSkuNamesOptions,
                setValue: setSelectedProductSkuNamesOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_shirt'),
                tooltipInformationValue: t('productPerformance.search_by_keywords')
            },
            {
                title: t('productPerformance.product_id'),
                value: selectedProductIdsOptions,
                setValue: setSelectedProductIdsOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_1123'),
                tooltipInformationValue: t('productPerformance.search_by_product_id')
            },
            {
                title: t('productPerformance.product_name'),
                value: selectedProductNamesOptions,
                setValue: setSelectedProductNamesOptions,
                type: "createValue",
                placeholder: t('productPerformance.type_and_press_enter_example_shirt'),
                tooltipInformationValue: t('productPerformance.search_by_keywords')
            },
            {
                title: t('productPerformance.departments'),
                options: productDepartmentsOptions,
                value: selectedProductDepartmentsOptions,
                setValue: setSelectedProductDepartmentsOptions
            },
            {
                title: t('productPerformance.categories'),
                options: productCategoriesOptions,
                value: selectedProductCategoriesOptions,
                setValue: setSelectedProductCategoriesOptions
            },
            {
                title: t('productPerformance.subcategories'),
                options: productSubCategoriesOptions,
                value: selectedProductSubCategoriesOptions,
                setValue: setSelectedProductSubCategoriesOptions
            },
            {
                title: t('productPerformance.product_brand'),
                options: productBrandsOptions,
                value: selectedProductBrandsOptions,
                setValue: setSelectedProductBrandsOptions

            },
            {
                title: t('productPerformance.day_of_the_week'),
                options: getWeekDaysFilterCustomLabel(weekDaysOptions),
                value: getWeekDaysFilterCustomLabel(selectedWeekDaysOptions),
                setValue: (values) => setSelectedWeekDaysOptions(getWeekDaysFilterValueByCustomLabel(values))
            },
            {
                title: t('productPerformance.coupons'),
                options: couponsOptions,
                value: selectedCouponsOptions,
                setValue: setSelectedCouponsOptions
            },
            {
                title: `${t('common.source')}`,
                options: originsOptions,
                value: selectedOriginsOptions,
                setValue: setSelectedOriginsOptions
            },
            {
                title: t('common.sales_channel'),
                options: salesChannelsOptions,
                value: selectedSalesChannelsOptions,
                setValue: setSelectedSalesChannelsOptions
            },
            {
                title: t('common.acquisition_channel'),
                options: acquisitionChannelOptions,
                value: selectedAcquisitionChannelOptions,
                setValue: setSelectedAcquisitionChannelOptions,
            },
            {
                title: `${t('common.affiliates_vtex')}`,
                options: vtexAffiliatesOptions,
                value: selectedVtexAffiliatesOptions,
                setValue: setSelectedVtexAffiliatesOptions
            },
            {
                title: t('productPerformance.warehouse_id_vtex'),
                options: vtexWarehousesIdsOptions,
                value: selectedVtexWarehousesIdsOptions,
                setValue: setSelectedVtexWarehousesIdsOptions
            },
            {
                title: t('productPerformance.smart_classification'),
                options: smartClassificationsOptions,
                value: selectedSmartClassificationsOptions,
                setValue: setSelectedSmartClassificationsOptions
            },
            {
                title: t('common.states'),
                options: statesOptions,
                value: selectedStatesOptions,
                setValue: setSelectedStatesOptions
            },
            {
                title: t('productPerformance.stock_min_max'),
                min: selectedMinProductStockOptions,
                max: selectedMaxProductStockOptions,
                setMin: setSelectedMinProductStockOptions,
                setMax: setSelectedMaxProductStockOptions,
                type: 'rangeNumber'
            },
        ]
    }

    const getCardIndicatorData = (data) => {
        return {
          keys: ["total_stock_quantity", "total_stock_value"],
          titles: {
            total_stock_quantity: t('productPerformance.stock_quantity'),
            total_stock_value: t('productPerformance.stock_value')
          },
          types: {
            total_stock_quantity: 'decimal',
            total_stock_value: 'currency'
          },
          displayFlexSpaceOcupation: {
            total_stock_quantity: 1,
            total_stock_value: 1
          },
          metricInfoConfig: {
            total_stock_quantity: {
              visible: false,
              onClick: null
            },
            total_stock_value: {
              visible: false,
              onClick: null
            }
          },
          data: data
        }
    }

    const onRenderComponent = useCallback(async () => {
        const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
        setDateRange([initialDateFilter, finalDateFilter]);

        await getDataMetrics({
          initialDate: formatDate(initialDateFilter),
          finalDate: formatDate(finalDateFilter)
        });
      }, [getDataMetrics]);

    useEffect(() => {
        onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.product_performance')}
        >
            <FilterContainer
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                onClickFilter={handleApplyFilter}
                showSecondaryFilters={true}
                secondaryFilters={getSecondaryFilters()}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbers)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('menu.product_performance')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading || isLoadingTablePagination}
            >
                <CustomTableApiPagination
                    data={getFormattedData(productsData)}
                    disabledDimensionController={productsData == null || productsData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={productPerformanceTableFullScreen}
                    setFullScreenEnabled={setProductPerformanceFullScreen}
                    currentPage={currentPage}
                    setCurrentPage={handleChangePage}
                    rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                    rowsPerPage={DEFAULT_ROWS_PER_PAGE}
                    setRowsPerPage={() => {}}
                    totalItems={totalItems}
                    showPagination={true}
                    handleExportCSVReport={() => handleExportReport('product')}
                    showExportCSVReportButton={true}
                    loadingExportCSVReportButton={exportReportLoading}
                />
            </CardGroup>
            <CardGroup
                title={t('productPerformance.category_performance')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <CustomCategoryTable
                    data={categoriesData}
                    showFullScreenOption={true}
                    fullScreenEnabled={categoriesPerformanceTableFullScreen}
                    setFullScreenEnabled={setCategoriesPerformanceFullScreen}
                    defineComparativeValueColor={defineComparativeValueColor}
                    formatComparativeValue={formatComparativeValue}
                    disable={categoriesData == null || categoriesData.length === 0}
                    loadingExportReportButton={exportReportLoading}
                    handleExportCSVReport={() => handleExportReport('category')}
                    showExportCSVReportButton={true}
                    loadingExportCSVReportButton={exportReportLoading}
                    currency={currency}
                />
            </CardGroup>
        </BackgroundNew>
    );
}

export default ProductPerformance;
