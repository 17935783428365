import React, { useState, useEffect, useCallback } from "react";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../../i18n";

import { InfoBar } from "./../../InfoBar";

import { parseDateToDayjs, formatDate, formatDateDayjsToBrazil } from "../../../utils/dateUtils";

import { sessionUserIsCompanyOwner } from "../../../utils/loginUtils";

import { getUserModules } from "../../../services/userModuleService";

import {
  getAuthorizationUrl,
  exchangeCodeForToken,
  generateStateIdentifier,
} from "../../../services/OAuthService";

import { updateFacebookAdsToken } from "../../../services/authService";

import { testFacebookAdsAccountAccess } from "../../../services/validationAccountAccess";

function InfoBarFacebookOAuthReconnect(props) {
  const { t } = useTranslation();

  const [oAuthInProgress, setOAuthInProgress] = useState(false);
  const [isNotificateOAuthExpiration, setIsNotificateOAuthExpiration] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [expirationDate, setExpirationDate] = useState("");
  const [facebookAdsAccountId, setFacebookAdsAccountId] = useState("");
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);

  const FACEBOOK_ADS_MODULE_NAME = "FA";
  const NOTIFICATION_DAYS_BEFORE_EXPIRATION = 10;

  const getNotificationDate = (expires_date) => {
    const date = parseDateToDayjs(new Date(expires_date));
    const notificationDate = date.subtract(NOTIFICATION_DAYS_BEFORE_EXPIRATION, "day");

    return formatDate(notificationDate);
  };

  const verifyUserSession = useCallback(() => {
    if (sessionUserIsCompanyOwner()) {
      setIsCompanyAdmin(true);
    }
  }, []);

  const initiateFacebookAdsOAuthFlow = async () => {
    if (oAuthInProgress) {
      return;
    }

    try {
      setOAuthInProgress(true);

      const stateValue = generateStateIdentifier();

      const response = await getAuthorizationUrl("facebook", stateValue);
      const oauthTab = window.open(response.url, "_blank");

      const handleMessage = async (event) => {
        try {
          const data = event.data;
          if (data && data.source === "oAuthFacebookAds") {
            const { completed, success, code, state } = data;

            if (completed) {
              window.removeEventListener("message", handleMessage);

              if (!success || state !== stateValue) {
                oauthTab.close();

                throw new Error();
              }

              oauthTab.close();

              const responseToken = await exchangeCodeForToken("facebook", code);

              if (!(await testFacebookAdsAccountAccess(facebookAdsAccountId, responseToken.token))) {
                toast.error(t("toast.facebook_auth_access_check_registered"));
                return;
              }

              await updateFacebookAdsToken(
                responseToken.token,
                responseToken.expires_date,
                getNotificationDate(responseToken.expires_date)
              );

              setIsNotificateOAuthExpiration(false);
              setIsExpired(false);

              toast.success(t("toast.auth_renewal_success"));
            }
          }
        } catch (error) {
          toast.error(t("toast.facebook_auth_failed"));
        } finally {
          setOAuthInProgress(false);
        }
      };

      window.addEventListener("message", handleMessage);
    } catch (error) {
      toast.error(t("toast.facebook_auth_initiate_failed"));
    }
  };

  const verifyExpirationDateFromFacebookAdsUserModule = useCallback(async () => {
    try {
      const response = await getUserModules();
      const userModulesFiltered = response.filter((item) => item.module === FACEBOOK_ADS_MODULE_NAME);

      if (!userModulesFiltered || userModulesFiltered.length === 0) {
        return;
      }

      const facebookAdsUserModule = JSON.parse(
        userModulesFiltered[0].settings[FACEBOOK_ADS_MODULE_NAME].profiles
      )[0];

      const facebookAdsUserModuleAccountId = facebookAdsUserModule["ID"];
      const facebookAdsUserModuleExpirationDate = facebookAdsUserModule["EXPIRATION_DATE"];
      const facebookAdsUserModuleNotificationDate = facebookAdsUserModule["NOTIFICATION_DATE"];

      if (!facebookAdsUserModuleNotificationDate || !facebookAdsUserModuleExpirationDate) {
        return;
      }

      const notificationDate = parseDateToDayjs(new Date(facebookAdsUserModuleNotificationDate));
      const today = parseDateToDayjs(new Date());

      if (today.isBefore(notificationDate)) {
        return;
      }

      const expirationDate = parseDateToDayjs(new Date(facebookAdsUserModuleExpirationDate));

      setExpirationDate(formatDateDayjsToBrazil(expirationDate));
      setFacebookAdsAccountId(facebookAdsUserModuleAccountId);

      setIsNotificateOAuthExpiration(true);
    } catch (error) {
      toast.error(t("toast.facebook_auth_expiration_failed"));
    }
  }, [t]);

  useEffect(() => {
    verifyUserSession();
    verifyExpirationDateFromFacebookAdsUserModule();
  }, [verifyUserSession, verifyExpirationDateFromFacebookAdsUserModule]);

  return (
    <>
      {(isNotificateOAuthExpiration || isExpired) && (
        <>
          <InfoBar {...props} onClick={isCompanyAdmin ? initiateFacebookAdsOAuthFlow : null}>
            <span>
              {isNotificateOAuthExpiration
                ? `${t("common.facebook_ads_auth_expiring")} ${expirationDate}.`
                : `${t("common.facebook_ads_auth_expired")} ${expirationDate}. ${t(
                    "common.please_renew"
                  )}.`}{" "}
              <strong>
                {isCompanyAdmin
                  ? `${t("common.click_here")}`
                  : `${t("common.login_to_company_admin_account")}`}
              </strong>{" "}
              {t("common.to_renew")}.
            </span>
          </InfoBar>
        </>
      )}
    </>
  );
}

export default InfoBarFacebookOAuthReconnect;
