import axios from "axios";

import i18n from "../i18n";

import { handleSignOut } from "../utils/loginUtils";
import { HttpStatusCode } from "axios";

const UNAUTHORIZED = 401;
const OK = 200;
const CREATED = 201;
const NO_CONTENT = 204;
const NOT_FOUND = 404;

const AUTH_ERROR_MESSAGE = i18n.t("error.authentication_failed");
const GENERIC_ERROR_MESSAGE = i18n.t("error.generic_error");
const USER_NOT_FOUND_MESSAGE = i18n.t("error.user_not_found");

const api = axios.create({
  baseURL: process.env.REACT_APP_ODIN_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === UNAUTHORIZED) {
      handleSignOut();
    }
    return error;
  }
);

const _makeAuthRequest = async (url, method, data) => {
  try {
    const response = await api({
      url,
      method,
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (!response || response.status !== OK) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    throw new Error(AUTH_ERROR_MESSAGE);
  }
};

const _makeRequest = async (url, method, data = null, params = null, authenticate = true) => {
  const token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
  };
  if (authenticate) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  if (params) {
    url += `?${new URLSearchParams(params).toString()}`;
  }
  try {
    const response = await api({
      url,
      method,
      data,
      headers,
    });

    if (response?.status === NOT_FOUND) {
      throw new Error("NOT_FOUND");
    }

    if (response?.status === NO_CONTENT) {
      return;
    }

    if (!response || ![OK, CREATED].includes(response?.status)) {
      const responseErrorCodes = response?.response?.data?.error_codes;
      const responseErrorCode = response?.response?.data?.error_code;
      if (responseErrorCodes) {
        throw new Error(responseErrorCodes[0].error_code);
      }
      if (responseErrorCode) {
        throw new Error(responseErrorCode);
      }
      throw new Error(GENERIC_ERROR_MESSAGE);
    }

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCurrentUser = async () => {
  try {
    const user = await _makeRequest("/my/user", "GET");

    return user;
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const validateToken = async () => {
  const response = await _makeRequest("/my/user", "GET");

  const unauthenticatedStatus = [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden];

  if (response && unauthenticatedStatus.includes(response.status)) {
    throw new Error();
  }

  return;
};

export const authenticate = async (username, password) => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  const response = await _makeAuthRequest("/auth", "POST", formData);
  return response.access_token;
};

export const resetPassword = async (username) => {
  try {
    await _makeRequest(`/reset/my/password`, "PUT", { username: username }, null, false);
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await _makeRequest(`/user`, "DELETE", null, { id: id });
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const createUser = async (data) => {
  const response = await _makeRequest("/user", "POST", data);

  return response;
};

export const createUsers = async (data) => {
  await _makeRequest("/users", "POST", { users: data });
};

export const editUser = async (data) => {
  try {
    const response = await _makeRequest(`/user`, "PATCH", data);

    return response;
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const editUserSettings = async (id, data) => {
  try {
    const response = await _makeRequest(`/user/${id}/settings`, "PATCH", data);

    return response;
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const editMyUserSettings = async (data) => {
  try {
    const response = await _makeRequest(`/my/user/settings`, "PATCH", data);

    return response;
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const editUserContactInfo = async (id, data) => {
  try {
    const response = await _makeRequest(`/user/${id}/contact_info`, "PATCH", data);

    return response;
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const editLoggedUserContactInfo = async (data) => {
  const response = await _makeRequest(`/my/user`, "PATCH", {
    contact_info: {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    },
  });

  return response;
};

export const editLoggedUserPassword = async (data) => {
  const response = await _makeRequest(`/my/user`, "PATCH", {
    old_password: data.old_password,
    new_password: data.new_password,
  });

  return response;
};

export const getUsers = async () => {
  const response = await _makeRequest("/my/company/users", "GET");

  return response;
};

export const getUser = async (user_id) => {
  try {
    const response = await _makeRequest("/user", "GET", null, { id: user_id });

    return response;
  } catch (error) {
    if (error.message === "NOT_FOUND") {
      throw new Error(USER_NOT_FOUND_MESSAGE);
    }
    throw error;
  }
};

export const getLoggedCompany = async () => {
  const response = await _makeRequest("/my/company", "GET");
  return response;
};

export const editLoggedCompany = async (data) => {
  const response = await _makeRequest("/my/company", "PATCH", data);
  return response;
};

export const editLoggedCompanySettingsTrello = async (data) => {
  const response = await _makeRequest("/my/company/settings_trello", "PATCH", data);
  return response;
};

export const getCompanies = async () => {
  const response = await _makeRequest("/companies", "GET");

  return response;
};

export const changeCompany = async (company_id) => {
  const response = await _makeRequest(`/auth/company/${company_id}`, "GET");

  return response;
};
