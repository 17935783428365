import React, { useCallback, useEffect, useState, useRef } from "react";
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";

import { Container } from './styles';

import CardGroup from '../../../components/CardGroup';

import { registerUserFeedback } from '../../../services/insightsService';

import pdfCover from '../../../assets/biso_insights_cover.jpg';
import pdfOutcome from '../../../assets/biso_insights_outcome.jpg';

import { ChannelInsight } from './ChannelInsight';

export const CompletedInsight = ({
    insight,
    onClose,
    setUserFeedbackToInsight,
    currency
}) => {
  const { t } = useTranslation();

  const [isCompany, setIsCompany] = useState(false);
  const [userFeedback, setUserFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isExportingPdf, setIsExportingPdf] = useState(false);

  const componentRef = useRef(null);

  const onRenderComponent = useCallback(() => {
    const { id, type, username } = JSON.parse(localStorage.getItem('user'));

    const isCompany = type === 'company';

    setIsCompany(isCompany);

    if (!isCompany) {
      const feedback = insight.company_users_feedbacks.find(feedback => feedback.profile_id === id);
      setUserFeedback(feedback);
      setUserInfo({ id, name: username });
    }
  }, [insight]);

  const sendUserFeedback = async (insightId, like) => {
    setLoading(true);

    try {
      await registerUserFeedback(insightId, like);

      const feedback = { like, profile_id: userInfo.id, profile_name: userInfo.name };
      setUserFeedbackToInsight(insightId, feedback);
      setUserFeedback(feedback);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const formartNumbersToBold = (text) => {
    const regex = /(?:R\$)?\s?\d+(?:,\d+)?%?/g;

    return text.replace(regex, '<b style="color:#FF0068;">$&</b>').replace(/\\n|\n/g, '<br>');
  }

  const handleExportInsightToPdf = () => {
    setIsExportingPdf(true);

    try {
      const element = componentRef.current;
  
      const elementClone = element.cloneNode(true);
  
      const elementsToHide = elementClone.querySelectorAll('.ignorePdf');
      elementsToHide.forEach(element => {
        element.style.visibility = 'hidden';
      });
  
      const options = {
        filename: `biso_insight_${insight.title}.pdf`,
        image: { type: 'jpeg' },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      };

      html2pdf()
        .set(options)
        .from(elementClone)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.insertPage(1);
          pdf.setPage(1);
          pdf.addImage(pdfCover, 'JPEG', 0, 0, 11, 8.5);
  
          pdf.addPage();
          pdf.addImage(pdfOutcome, 'JPEG', 0, 0, 11, 8.5);
  
          pdf.save(options.filename);
  
          elementClone.remove();
  
          setIsExportingPdf(false);
        });
    } catch (error) {
      toast.error(t('toast.insight_export_pdf_failed'));
      setIsExportingPdf(false);
    }
  }

  const renderTable = (data, fields) => {
    return (
      <div className="tableInsightsContainer">
        <table className="tableInsights">
          <thead>
            <tr>
              {fields.map((field, index) => {
                return (
                  <th key={index}>{field.label}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  {fields.map((field, index) => {
                    return (
                      <td key={index} style={{minWidth: field.minWidth, maxWidth: field.maxWidth}}>{field.formatter ? field.formatter(item[field.value]) : item[field.value]}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const renderInsight = (insight) => {
    if (!insight){ return null; }

    if (["CRM", "PAID_MEDIA"].includes(insight.analysis_type)) {
      return (
        <ChannelInsight 
          insight={insight}
          renderTable={renderTable}
          formartNumbersToBold={formartNumbersToBold}
          currency={currency}
        />
      );
    }

    return null
  }

  useEffect(() => {
    onRenderComponent();
  }, [onRenderComponent]);

  return (
    <span ref={componentRef}>
      <CardGroup
          title={insight.title}
          tagIdentifierColor="#ff0068"
          showBackButton={true}
          backButtonOnClick={onClose}
          showExportPDFButton={true}
          exportPDFButtonOnClick={handleExportInsightToPdf}
          exportPDFButtonDisable={isExportingPdf}
          exportPDFButtonEnableLoading={true}
          exportPDFButtonOnLoading={isExportingPdf}
        >
          <Container>
            {renderInsight(insight)}
            {!isCompany && !userFeedback && (
              <div className="usersFeedback">
                <p>{t('bisoInsights.insight_useful')}</p>
                <div className="usersFeedbackActions">
                  <button 
                    className="usersFeedbackAction" 
                    disabled={loading}
                    onClick={_ => sendUserFeedback(insight.id, true)}
                  >
                    <AiOutlineLike size={18}/>
                  </button>
                  <button 
                    className="usersFeedbackAction" 
                    disabled={loading}
                    onClick={_ => sendUserFeedback(insight.id, false)}
                  >
                    <AiOutlineDislike size={18}/>
                  </button>
                </div>
              </div>
            )}
            {!isCompany && userFeedback && (
              <div className="usersFeedback">
                <p>{t('bisoInsights.you_rated_this_insight_as')} {userFeedback.like ? t('bisoInsights.useful') : t('bisoInsights.not_useful')}.</p>
              </div>
            )}
          </Container>
      </CardGroup>
    </span>
  );
}