import axios from 'axios';

import i18n from '../i18n';

import { handleSignOut } from '../utils/loginUtils';

const BAD_REQUEST = 400;
const OK = 200;

const ERROR_MESSAGE = i18n.t('error.data_retrieve_failed');

const api = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      handleSignOut();
    }
    return error;
  }
);

const _makeRequest = async (url, method, data = null) => {
  const token = localStorage.getItem("token");

  try {
    const response = await api({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response && response.response && response.response.status === BAD_REQUEST) {
      return null;
    }

    if (!response || (response.response && response.response.status !== OK)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    throw new Error(ERROR_MESSAGE);
  }
};

export const getControlPainelMetrics = async (intialDate, finalDate, timezone = null) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
  };

  return _makeRequest("/media/metrics/control_painel", "POST", filter);
};

export const getGoalIndicatorsEvolutionMetrics = async (intialDate, finalDate, channels = null) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    channels,
  };

  return _makeRequest("/media/metrics/goal/indicators_evolution", "POST", filter);
};

export const getMediaOverviewMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  channels = null,
  campaigns = null,
  sourcesMedium = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    channels,
    campaigns,
    sources_medium: sourcesMedium,
  };

  return _makeRequest("/media/metrics/overview", "POST", filter);
};

export const getMediaCampaignsMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  channels = null,
  campaigns = null,
  sourcesMedium = null,
  paginate = false,
  page = 1,
  pageSize = 3000
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    channels,
    campaigns,
    sources_medium: sourcesMedium,
    paginate,
    page,
    page_size: pageSize,
  };

  return _makeRequest("/media/metrics/campaigns", "POST", filter);
};

export const sendReportToEmailMediaCampaignsMetrics = async (
  intialDate,
  finalDate,
  reportType,
  timezone = null,
  channels = null,
  campaigns = null,
  sourcesMedium = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    channels,
    campaigns,
    sources_medium: sourcesMedium,
  };

  return _makeRequest(`/media/metrics/campaigns/report/email?report_type=${reportType}`, "POST", filter);
};

export const getGoogleAnalyticsDailyMetricsWithPredictions = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    channel: filter.channel || null,
  };

  return _makeRequest("/media/metrics/daily_with_predictions", "POST", filter_request);
};

export const getGoogleAnalyticsDailyMetricsWithAnomalies = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    channel: filter.channel || null,
  };

  return _makeRequest("/media/metrics/daily_with_anomalies", "POST", filter_request);
};

export const getCRMDailyMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest("/crm/metrics/daily", "POST", filter);
};

export const getCRMBigNumbersMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest("/crm/metrics/big_numbers", "POST", filter);
};

export const getCRMAverageTimeBetweenPurchasesMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest("/crm/avg_time_between_purchases", "POST", filter);
};

export const getEcommerceControlPainelMetrics = async (intialDate, finalDate, timezone = null) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
  };

  return _makeRequest("/ecommerce/metrics/macro_view", "POST", filter);
};

export const getEcommerceDailyMetricsWithPredictions = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
  };

  return _makeRequest("/ecommerce/metrics/daily_with_predictions", "POST", filter_request);
};

export const getCRMGeolocationMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  states = null,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    states,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest("/crm/geolocation/states", "POST", filter);
};

export const getCRMDecilesMetrics = async (
  intialDate,
  finalDate,
  timezone = null,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest("/crm/deciles", "POST", filter);
};

export const getCRMRfvMetrics = async (
  timezone,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    timezone,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest(`/crm/rfv`, "POST", filter);
};

export const getCRMCohortMetrics = async (
  timezone,
  origins = null,
  salesChannels = null,
  vtexAffiliates = null
) => {
  const filter = {
    timezone,
    origins,
    marketplace_channels: salesChannels,
    vtex_affiliates: vtexAffiliates,
  };

  return _makeRequest(`/crm/cohort`, "POST", filter);
};

export const getConnectorsLastSyncDate = async (timezone) => {
  return _makeRequest(`/config/connectors/last_sync_date?timezone=${timezone}`, "GET");
};

export const getCustomerBehavior = async (filter, page, pageSize) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    cities: filter.cities,
    states: filter.states,
    product_sku_ids: filter.productSkuIds,
    product_sku_names: filter.productSkuNames,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    customer_frequencies: filter.customerFrequencies,
    customer_recencies: filter.customerRecencies,
    customer_deciles: filter.customerDeciles,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    channels: filter.channels,
    page,
    page_size: pageSize,
  };
 
  return _makeRequest("/customer_behavior/", "POST", filter_request);
};

export const sendReportToEmailCustomerBehavior = async (filter, page) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    cities: filter.cities,
    states: filter.states,
    product_sku_ids: filter.productSkuIds,
    product_sku_names: filter.productSkuNames,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    customer_frequencies: filter.customerFrequencies,
    customer_recencies: filter.customerRecencies,
    customer_deciles: filter.customerDeciles,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    channels: filter.channels,
    page
  };

  return _makeRequest("/customer_behavior/report/email", "POST", filter_request);
};

export const getMarketplaceOverview = async (
  intialDate,
  finalDate,
  timezone,
  states = null,
  marketplaceChannels = null,
  affiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    states,
    marketplace_channels: marketplaceChannels,
    vtex_affiliates: affiliates,
  };

  return _makeRequest("/marketplace/general_view", "POST", filter);
};

export const getMarketplaceComparative = async (
  intialDate,
  finalDate,
  timezone,
  states = null,
  marketplaceChannels = null,
  affiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    states,
    marketplace_channels: marketplaceChannels,
    vtex_affiliates: affiliates,
  };

  return _makeRequest("/marketplace/comparative_view", "POST", filter);
};

export const sendReportToEmailMarketplaceComparative = async (
  intialDate,
  finalDate,
  timezone,
  states = null,
  marketplaceChannels = null,
  affiliates = null
) => {
  const filter = {
    initial_date: intialDate,
    final_date: finalDate,
    timezone,
    states,
    marketplace_channels: marketplaceChannels,
    vtex_affiliates: affiliates,
  };

  return _makeRequest("/marketplace/comparative_view/report/email", "POST", filter);
};

export const getProductPerformance = async (filter, page, pageSize) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    product_sku_ids: filter.productSkuIds,
    product_sku_names: filter.productSkuNames,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    min_product_stock_quantity: filter.minProductStockQuantity,
    max_product_stock_quantity: filter.maxProductStockQuantity,
    week_days: filter.weekDays,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    vtex_warehouse_ids: filter.vtexWarehouseIds,
    smart_classifications: filter.smartClassifications,
    states: filter.states,
    product_brands: filter.productBrands,
    channels: filter.channels,
    page,
    page_size: pageSize,
  };

  return _makeRequest("/product/performance", "POST", filter_request);
};

export const sendReportToEmailProductPerformance = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    product_sku_ids: filter.productSkuIds,
    product_sku_names: filter.productSkuNames,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    min_product_stock_quantity: filter.minProductStockQuantity,
    max_product_stock_quantity: filter.maxProductStockQuantity,
    week_days: filter.weekDays,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    vtex_warehouse_ids: filter.vtexWarehouseIds,
    smart_classifications: filter.smartClassifications,
    states: filter.states,
    product_brands: filter.productBrands,
    channels: filter.channels,
  };

  return _makeRequest("/product/performance/report/email", "POST", filter_request);
};

export const getCategoriesPerformance = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    product_sku_ids: filter.productSkuIds,
    product_sku_names: filter.productSkuNames,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    min_product_stock_quantity: filter.minProductStockQuantity,
    max_product_stock_quantity: filter.maxProductStockQuantity,
    week_days: filter.weekDays,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    vtex_warehouse_ids: filter.vtexWarehouseIds,
    smart_classifications: filter.smartClassifications,
    states: filter.states,
    product_brands: filter.productBrands,
    channels: filter.channels,
  };

  return _makeRequest("/product/performance/categories", "POST", filter_request);
};

export const sendReportToEmailCategoriesPerformance = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    product_sku_ids: filter.productSkuIds,
    product_sku_names: filter.productSkuNames,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    min_product_stock_quantity: filter.minProductStockQuantity,
    max_product_stock_quantity: filter.maxProductStockQuantity,
    week_days: filter.weekDays,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    vtex_warehouse_ids: filter.vtexWarehouseIds,
    smart_classifications: filter.smartClassifications,
    states: filter.states,
    product_brands: filter.productBrands,
    channels: filter.channels,
  };

  return _makeRequest("/product/performance/categories/report/email", "POST", filter_request);
};

export const getIntelipostMostCommunDeliveryTimeByShippingCompany = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    shipping_companies: filter.shippingCompanies,
    states: filter.states,
    cities: filter.cities,
    quarters: filter.quarters,
  };

  return _makeRequest(
    "/logistic/intelipost/most_commun_delivery_time_grouped_by_shipping_company",
    "POST",
    filter_request
  );
};

export const getIntelipostMostCommunDeliveryTimeByQuarters = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    shipping_companies: filter.shippingCompanies,
    states: filter.states,
    cities: filter.cities,
    quarters: filter.quarters,
  };

  return _makeRequest(
    "/logistic/intelipost/most_commun_delivery_time_grouped_by_quarter",
    "POST",
    filter_request
  );
};

export const getIntelipostAddressesWithDeliveryFailuresByShippingCompany = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    shipping_companies: filter.shippingCompanies,
    states: filter.states,
    cities: filter.cities,
    quarters: filter.quarters,
  };

  return _makeRequest(
    "/logistic/intelipost/addresses_with_delivery_failures_grouped_by_shipping_company",
    "POST",
    filter_request
  );
};

export const getIntelipostAddressesWithDeliveryFailuresByQuarters = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    shipping_companies: filter.shippingCompanies,
    states: filter.states,
    cities: filter.cities,
    quarters: filter.quarters,
  };

  return _makeRequest(
    "/logistic/intelipost/addresses_with_delivery_failures_grouped_by_quarter",
    "POST",
    filter_request
  );
};

export const getIntelipostShipmentOrdersWithDeliveryProblem = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_companies: filter.shippingCompanies,
    states: filter.states,
    cities: filter.cities,
    quarters: filter.quarters,
  };

  return _makeRequest("/logistic/intelipost/shipment_orders_with_delivery_problem", "POST", filter_request);
};

export const getIntelipostShipmentOrdersWithPlpProblem = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_companies: filter.shippingCompanies,
    states: filter.states,
    cities: filter.cities,
    quarters: filter.quarters,
  };

  return _makeRequest("/logistic/intelipost/shipment_orders_with_plp_problem", "POST", filter_request);
};

export const getLogisticShippingMethodEvolutionMetric = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_methods: filter.shippingMethods,
    cities: filter.cities,
    states: filter.states,
    marketplace_channels: filter.salesChannels,
    origins: filter.origins,
    shipping_types: filter.shippingTypes,
  };
  return _makeRequest("/logistic/shipping_method_evolution_metrics", "POST", filter_request);
};

export const getLogisticEvolutionAverageShippingCostRepresentationPerOrder = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_methods: filter.shippingMethods,
    states: filter.states,
    cities: filter.cities,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    shipping_types: filter.shippingTypes,
  };
  return _makeRequest(
    "/logistic/average_shipping_cost_representation_per_order_evolution_metrics",
    "POST",
    filter_request
  );
};

export const getLogisticEvolutionAverageShippingCostProductRepresentation = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_methods: filter.shippingMethods,
    states: filter.states,
    cities: filter.cities,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    product_ids: filter.productIds,
    product_sku_ids: filter.productSkuIds,
    shipping_types: filter.shippingTypes,
  };
  return _makeRequest(
    "/logistic/average_shipping_cost_representation_per_product_evolution_metrics",
    "POST",
    filter_request
  );
};

export const getLogisticShippingTypeEvolutionMetric = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_methods: filter.shippingMethods,
    states: filter.states,
    cities: filter.cities,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    shipping_types: filter.shippingTypes,
  };
  return _makeRequest("/logistic/shipping_type_evolution_metrics", "POST", filter_request);
};

export const getLogisticAverageShippingCostByShippingTypePerOrder = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    shipping_methods: filter.shippingMethods,
    states: filter.states,
    cities: filter.cities,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    shipping_types: filter.shippingTypes,
  };
  return _makeRequest(
    "/logistic/average_shipping_cost_by_shipping_type_per_order_evolution_metrics",
    "POST",
    filter_request
  );
};

export const getPlatformOverviewGroupByOriginAndChannel = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    states: filter.states,
    marketplace_channels: filter.marketplaceChannels,
    origins: filter.origins,
    vtex_affiliates: filter.vtexAffiliates,
  };

  return _makeRequest("/platform/overview/group_by_origin_and_channel", "POST", filter_request);
};

export const getProductBehaviorAbc = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    week_days: filter.weekDays,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    vtex_warehouse_ids: filter.vtexWarehouseIds,
    abc_classification: filter.abcClassification,
  };

  return _makeRequest('/product_behavior/abc', 'POST', filter_request);
};

export const sendReportToEmailProductBehaviorAbc = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    product_ids: filter.productIds,
    product_names: filter.productNames,
    product_categories: filter.productCategories,
    product_subcategories: filter.productSubcategories,
    product_departments: filter.productDepartments,
    week_days: filter.weekDays,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    vtex_warehouse_ids: filter.vtexWarehouseIds,
    abc_classification: filter.abcClassification,
  };

  return _makeRequest('/product_behavior/abc/report/email', 'POST', filter_request);
};

export const getPaymentDetailsMetrics = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    payment_methods: filter.paymentMethod,
    forms_of_payments: filter.formsOfPayment

  };

  return _makeRequest('/payment/payment_details_metrics', 'POST', filter_request);
};

export const getPaymentInstallmentMetrics = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    payment_methods: filter.paymentMethod,
    forms_of_payments: filter.formsOfPayment

  };

  return _makeRequest('payment/installments_metrics', 'POST', filter_request);
};

export const sendPaymentDetailsReportByEmail = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    payment_methods: filter.paymentMethod,
    forms_of_payments: filter.formsOfPayment
  };

  return _makeRequest('/payment/payment_details_metrics/report/email', 'POST', filter_request);
};

export const getPromotionsDailyOverview = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    promotion_names: filter.promotionNames,
    promotion_types: filter.promotionTypes,
    promotion_status: filter.promotionStatus,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    week_days: filter.weekDays,
    vtex_affiliates: filter.vtexAffiliates,
  };

  return _makeRequest('/promotion/daily_overview', 'POST', filter_request);
}

export const getPromotionsCustomerUsageFrequency = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    promotion_names: filter.promotionNames,
    promotion_types: filter.promotionTypes,
    promotion_status: filter.promotionStatus,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    week_days: filter.weekDays,
    vtex_affiliates: filter.vtexAffiliates,
  };

  return _makeRequest('/promotion/customer_usage_frequency', 'POST', filter_request);
}

export const sendPromotionsOverviewReportByEmail = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    promotion_names: filter.promotionNames,
    promotion_types: filter.promotionTypes,
    promotion_status: filter.promotionStatus,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    week_days: filter.weekDays,
    vtex_affiliates: filter.vtexAffiliates,
  };

  return _makeRequest('/promotion/overview/report/email', 'POST', filter_request);
}


export const getCouponDailyOverview = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    promotion_names: filter.promotionNames,
    promotion_types: filter.promotionTypes,
    promotion_status: filter.promotionStatus,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    week_days: filter.weekDays,
  };

  return _makeRequest('/coupon/daily_overview', 'POST', filter_request);
}


export const sendCouponsDailyOverviewReportByEmail = async (filter) => {
  const filter_request = {
    initial_date: filter.initialDate,
    final_date: filter.finalDate,
    timezone: filter.timezone,
    promotion_names: filter.promotionNames,
    promotion_types: filter.promotionTypes,
    promotion_status: filter.promotionStatus,
    coupons: filter.coupons,
    origins: filter.origins,
    marketplace_channels: filter.salesChannels,
    vtex_affiliates: filter.vtexAffiliates,
    week_days: filter.weekDays,
  };

  return _makeRequest('/coupon/overview/report/email', 'POST', filter_request);
}
