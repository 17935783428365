import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ptBRCommon from "./locales/ptBr/common.json";
import enCommon from './locales/en/common.json';
import esCommon from './locales/es/common.json';
import ptBrToast from './locales/ptBr/toast.json';
import enToast from './locales/en/toast.json';
import esToast from './locales/es/toast.json';
import ptBrError from './locales/ptBr/error.json';
import enError from './locales/en/error.json';
import esError from './locales/es/error.json';
import ptBrOAuth from './locales/ptBr/oauth.json';
import enOAuth from './locales/en/oauth.json';
import esOAuth from './locales/es/oauth.json';
import ptBrLtv from './locales/ptBr/ltv.json';
import enLtv from './locales/en/ltv.json';
import esLtv from './locales/es/ltv.json';
import ptBrFirstStep from './locales/ptBr/firstStep.json';
import enFirstStep from './locales/en/firstStep.json';
import esFirstStep from './locales/es/firstStep.json';
import ptBrIntelipostCallToAction from './locales/ptBr/intelipostCallToAction.json';
import enIntelipostCallToAction from './locales/en/intelipostCallToAction.json';
import esIntelipostCallToAction from './locales/es/intelipostCallToAction.json';
import ptBrMenu from './locales/ptBr/menu.json';
import enMenu from './locales/en/menu.json';
import esMenu from './locales/es/menu.json';
import ptBrAppBar from './locales/ptBr/appBar.json';
import enAppBar from './locales/en/appBar.json';
import esAppBar from './locales/es/appBar.json';
import ptBrGoalsOverview from './locales/ptBr/goalsOverview.json';
import enGoalsOverview from './locales/en/goalsOverview.json';
import esGoalsOverview from './locales/es/goalsOverview.json';
import ptBrGoalsEvolution from './locales/ptBr/goalsEvolution.json';
import enGoalsEvolution from './locales/en/goalsEvolution.json';
import esGoalsEvolution from './locales/es/goalsEvolution.json';
import ptBrMarketplaceOverview from './locales/ptBr/marketplaceOverview.json';
import enMarketplaceOverview from './locales/en/marketplaceOverview.json';
import esMarketplaceOverview from './locales/es/marketplaceOverview.json';
import ptBrOperationOverview from './locales/ptBr/operationOverview.json';
import enOperationOverview from './locales/en/operationOverview.json';
import esOperationOverview from './locales/es/operationOverview.json';
import ptBrPaidMediaCampaign from './locales/ptBr/paidMediaCampaign.json';
import enPaidMediaCampaign from './locales/en/paidMediaCampaign.json';
import esPaidMediaCampaign from './locales/es/paidMediaCampaign.json';
import ptBrPaidMediaOverview from './locales/ptBr/paidMediaOverview.json';
import enPaidMediaOverview from './locales/en/paidMediaOverview.json';
import esPaidMediaOverview from './locales/es/paidMediaOverview.json';
import ptBrAllChannel from './locales/ptBr/allChannel.json';
import enAllChannel from './locales/en/allChannel.json';
import esAllChannel from './locales/es/allChannel.json';
import ptBrMailMarketing from './locales/ptBr/mailMarketing.json';
import enMailMarketing from './locales/en/mailMarketing.json';
import esMailMarketing from './locales/es/mailMarketing.json';
import ptBrAudienceOverview from './locales/ptBr/audienceOverview.json';
import enAudienceOverview from './locales/en/audienceOverview.json';
import esAudienceOverview from './locales/es/audienceOverview.json';
import ptBrGeolocation from './locales/ptBr/geolocation.json';
import enGeolocation from './locales/en/geolocation.json';
import esGeolocation from './locales/es/geolocation.json';
import ptBrDecil from './locales/ptBr/decil.json';
import enDecil from './locales/en/decil.json';
import esDecil from './locales/es/decil.json';
import ptBrRfv from './locales/ptBr/rfv.json';
import enRfv from './locales/en/rfv.json';
import esRfv from './locales/es/rfv.json';
import ptBrCohort from './locales/ptBr/cohort.json';
import enCohort from './locales/en/cohort.json';
import esCohort from './locales/es/cohort.json';
import ptBrProductPerformance from './locales/ptBr/productPerformance.json';
import enProductPerformance from './locales/en/productPerformance.json';
import esProductPerformance from './locales/es/productPerformance.json';
import ptBrProductBehaviorCurveAbc from './locales/ptBr/productBehaviorCurveAbc.json';
import enProductBehaviorCurveAbc from './locales/en/productBehaviorCurveAbc.json';
import esProductBehaviorCurveAbc from './locales/es/productBehaviorCurveAbc.json';
import ptBrLogisticShippingMethodEvolution from './locales/ptBr/logisticShippingMethodEvolution.json';
import enLogisticShippingMethodEvolution from './locales/en/logisticShippingMethodEvolution.json';
import esLogisticShippingMethodEvolution from './locales/es/logisticShippingMethodEvolution.json';
import ptBrLogisticAverageShippingCostEvolution from './locales/ptBr/logisticAverageShippingCostEvolution.json';
import enLogisticAverageShippingCostEvolution from './locales/en/logisticAverageShippingCostEvolution.json';
import esLogisticAverageShippingCostEvolution from './locales/es/logisticAverageShippingCostEvolution.json';
import ptBrLogisticShippingTypeEvolution from './locales/ptBr/logisticShippingTypeEvolution.json';
import enLogisticShippingTypeEvolution from './locales/en/logisticShippingTypeEvolution.json';
import esLogisticShippingTypeEvolution from './locales/es/logisticShippingTypeEvolution.json';
import ptBrLogisticIntelipostMostCommunDeliveryTime from './locales/ptBr/logisticIntelipostMostCommunDeliveryTime.json';
import enLogisticIntelipostMostCommunDeliveryTime from './locales/en/logisticIntelipostMostCommunDeliveryTime.json';
import esLogisticIntelipostMostCommunDeliveryTime from './locales/es/logisticIntelipostMostCommunDeliveryTime.json';
import ptBrLogisticIntelipostAddressesWithDeliveryFailures from './locales/ptBr/logisticIntelipostAddressesWithDeliveryFailures.json';
import enLogisticIntelipostAddressesWithDeliveryFailures from './locales/en/logisticIntelipostAddressesWithDeliveryFailures.json';
import esLogisticIntelipostAddressesWithDeliveryFailures from './locales/es/logisticIntelipostAddressesWithDeliveryFailures.json';
import ptBrAlerts from './locales/ptBr/alerts.json';
import enAlerts from './locales/en/alerts.json';
import esAlerts from './locales/es/alerts.json';
import ptBrMediaPlanner from './locales/ptBr/mediaPlanner.json';
import enMediaPlanner from './locales/en/mediaPlanner.json';
import esMediaPlanner from './locales/es/mediaPlanner.json';
import ptBrGoalsManager from './locales/ptBr/goalsManager.json';
import enGoalsManager from './locales/en/goalsManager.json';
import esGoalsManager from './locales/es/goalsManager.json';
import ptBrAnomalies from './locales/ptBr/anomalies.json';
import enAnomalies from './locales/en/anomalies.json';
import esAnomalies from './locales/es/anomalies.json';
import ptBrAdTextCreator from './locales/ptBr/adTextCreator.json';
import enAdTextCreator from './locales/en/adTextCreator.json';
import esAdTextCreator from './locales/es/adTextCreator.json';
import ptBrSmartReportMinimumStock from './locales/ptBr/smartReportMinimumStock.json';
import enSmartReportMinimumStock from './locales/en/smartReportMinimumStock.json';
import esSmartReportMinimumStock from './locales/es/smartReportMinimumStock.json';
import ptBrCustomerBehavior from './locales/ptBr/customerBehavior.json';
import enCustomerBehavior from './locales/en/customerBehavior.json';
import esCustomerBehavior from './locales/es/customerBehavior.json';
import ptBrBisoInsights from './locales/ptBr/bisoInsights.json';
import enBisoInsights from './locales/en/bisoInsights.json';
import esBisoInsights from './locales/es/bisoInsights.json';
import ptBrServiceMarketplace from './locales/ptBr/serviceMarketplace.json';
import enServiceMarketplace from './locales/en/serviceMarketplace.json';
import esServiceMarketplace from './locales/es/serviceMarketplace.json';
import ptBrUsers from './locales/ptBr/users.json';
import enUsers from './locales/en/users.json';
import esUsers from './locales/es/users.json';
import ptBrNotifications from './locales/ptBr/notifications.json';
import enNotifications from './locales/en/notifications.json';
import esNotifications from './locales/es/notifications.json';
import ptBrProfile from './locales/ptBr/profile.json';
import enProfile from './locales/en/profile.json';
import esProfile from './locales/es/profile.json';
import ptBrSignIn from './locales/ptBr/signIn.json';
import enSignIn from './locales/en/signIn.json';
import esSignIn from './locales/es/signIn.json';
import ptBrDataSyncInfo from './locales/ptBr/dataSyncInfo.json';
import enDataSyncInfo from './locales/en/dataSyncInfo.json';
import esDataSyncInfo from './locales/es/dataSyncInfo.json';
import ptBrConnectionAndChannel from './locales/ptBr/connectionAndChannel.json';
import enConnectionAndChannel from './locales/en/connectionAndChannel.json';
import esConnectionAndChannel from './locales/es/connectionAndChannel.json';
import ptBrPreferences from './locales/ptBr/preferences.json';
import enPreferences from './locales/en/preferences.json';
import esPreferences from './locales/es/preferences.json';
import ptBrOnesignal from './locales/ptBr/onesignal.json';
import enOnesignal from './locales/en/onesignal.json';
import esOnesignal from './locales/es/onesignal.json';
import ptBrPaymentOverview from './locales/ptBr/paymentOverview.json';
import enPaymentOverview from'./locales/en/paymentOverview.json';
import esPaymentOverview from './locales/es/paymentOverview.json';
import ptPromotionPerformance from './locales/ptBr/promotionPerformance.json';
import enPromotionPerformance from './locales/en/promotionPerformance.json';
import esPromotionPerformance from './locales/es/promotionPerformance.json';

i18n.use(initReactI18next).init({
    lng: "pt_br",
    fallbackLng: "pt_br",
    resources: {
        pt_br: {
            translation: {
                common: ptBRCommon,
                toast: ptBrToast,
                error: ptBrError,
                oauth: ptBrOAuth,
                ltv: ptBrLtv,
                firstStep: ptBrFirstStep,
                intelipostCallToAction: ptBrIntelipostCallToAction,
                menu: ptBrMenu,
                appBar: ptBrAppBar,
                goalsOverview: ptBrGoalsOverview,
                goalsEvolution: ptBrGoalsEvolution,
                marketplaceOverview: ptBrMarketplaceOverview,
                operationOverview: ptBrOperationOverview,
                paidMediaCampaign: ptBrPaidMediaCampaign,
                paidMediaOverview: ptBrPaidMediaOverview,
                allChannel: ptBrAllChannel,
                mailMarketing: ptBrMailMarketing,
                audienceOverview: ptBrAudienceOverview,
                geolocation: ptBrGeolocation,
                decil: ptBrDecil,
                rfv: ptBrRfv,
                cohort: ptBrCohort,
                productPerformance: ptBrProductPerformance,
                productBehaviorCurveAbc: ptBrProductBehaviorCurveAbc,
                logisticShippingMethodEvolution: ptBrLogisticShippingMethodEvolution,
                logisticAverageShippingCostEvolution: ptBrLogisticAverageShippingCostEvolution,
                logisticShippingTypeEvolution: ptBrLogisticShippingTypeEvolution,
                logisticIntelipostMostCommunDeliveryTime: ptBrLogisticIntelipostMostCommunDeliveryTime,
                logisticIntelipostAddressesWithDeliveryFailures: ptBrLogisticIntelipostAddressesWithDeliveryFailures,
                alerts: ptBrAlerts,
                mediaPlanner: ptBrMediaPlanner,
                goalsManager: ptBrGoalsManager,
                anomalies: ptBrAnomalies,
                adTextCreator: ptBrAdTextCreator,
                smartReportMinimumStock: ptBrSmartReportMinimumStock,
                customerBehavior: ptBrCustomerBehavior,
                bisoInsights: ptBrBisoInsights,
                serviceMarketplace: ptBrServiceMarketplace,
                users: ptBrUsers,
                notifications: ptBrNotifications,
                profile: ptBrProfile,
                signIn: ptBrSignIn,
                dataSyncInfo: ptBrDataSyncInfo,
                connectionAndChannel: ptBrConnectionAndChannel,
                preferences: ptBrPreferences,
                onesignal: ptBrOnesignal,
                paymentOverview: ptBrPaymentOverview,
                promotionPerformance: ptPromotionPerformance
            }
        },
        en_us: {
            translation: {
                common: enCommon,
                toast: enToast,
                error: enError,
                oauth: enOAuth,
                ltv: enLtv,
                firstStep: enFirstStep,
                intelipostCallToAction: enIntelipostCallToAction,
                menu: enMenu,
                appBar: enAppBar,
                goalsOverview: enGoalsOverview,
                goalsEvolution: enGoalsEvolution,
                marketplaceOverview: enMarketplaceOverview,
                operationOverview: enOperationOverview,
                paidMediaCampaign: enPaidMediaCampaign,
                paidMediaOverview: enPaidMediaOverview,
                allChannel: enAllChannel,
                mailMarketing: enMailMarketing,
                audienceOverview: enAudienceOverview,
                geolocation: enGeolocation,
                decil: enDecil,
                rfv: enRfv,
                cohort: enCohort,
                productPerformance: enProductPerformance,
                productBehaviorCurveAbc: enProductBehaviorCurveAbc,
                logisticShippingMethodEvolution: enLogisticShippingMethodEvolution,
                logisticAverageShippingCostEvolution: enLogisticAverageShippingCostEvolution,
                logisticShippingTypeEvolution: enLogisticShippingTypeEvolution,
                logisticIntelipostMostCommunDeliveryTime: enLogisticIntelipostMostCommunDeliveryTime,
                logisticIntelipostAddressesWithDeliveryFailures: enLogisticIntelipostAddressesWithDeliveryFailures,
                alerts: enAlerts,
                mediaPlanner: enMediaPlanner,
                goalsManager: enGoalsManager,
                anomalies: enAnomalies,
                adTextCreator: enAdTextCreator,
                smartReportMinimumStock: enSmartReportMinimumStock,
                customerBehavior: enCustomerBehavior,
                bisoInsights: enBisoInsights,
                serviceMarketplace: enServiceMarketplace,
                users: enUsers,
                notifications: enNotifications,
                profile: enProfile,
                signIn: enSignIn,
                dataSyncInfo: enDataSyncInfo,
                connectionAndChannel: enConnectionAndChannel,
                preferences: enPreferences,
                onesignal: enOnesignal,
                paymentOverview: enPaymentOverview,
                promotionPerformance: enPromotionPerformance
            }
        },
        es: {
            translation: {
                common: esCommon,
                toast: esToast,
                error: esError,
                oauth: esOAuth,
                ltv: esLtv,
                firstStep: esFirstStep,
                intelipostCallToAction: esIntelipostCallToAction,
                menu: esMenu,
                appBar: esAppBar,
                goalsOverview: esGoalsOverview,
                goalsEvolution: esGoalsEvolution,
                marketplaceOverview: esMarketplaceOverview,
                operationOverview: esOperationOverview,
                paidMediaCampaign: esPaidMediaCampaign,
                paidMediaOverview: esPaidMediaOverview,
                allChannel: esAllChannel,
                mailMarketing: esMailMarketing,
                audienceOverview: esAudienceOverview,
                geolocation: esGeolocation,
                decil: esDecil,
                rfv: esRfv,
                cohort: esCohort,
                productPerformance: esProductPerformance,
                productBehaviorCurveAbc: esProductBehaviorCurveAbc,
                logisticShippingMethodEvolution: esLogisticShippingMethodEvolution,
                logisticAverageShippingCostEvolution: esLogisticAverageShippingCostEvolution,
                logisticShippingTypeEvolution: esLogisticShippingTypeEvolution,
                logisticIntelipostMostCommunDeliveryTime: esLogisticIntelipostMostCommunDeliveryTime,
                logisticIntelipostAddressesWithDeliveryFailures: esLogisticIntelipostAddressesWithDeliveryFailures,
                alerts: esAlerts,
                mediaPlanner: esMediaPlanner,
                goalsManager: esGoalsManager,
                anomalies: esAnomalies,
                adTextCreator: esAdTextCreator,
                smartReportMinimumStock: esSmartReportMinimumStock,
                customerBehavior: esCustomerBehavior,
                bisoInsights: esBisoInsights,
                serviceMarketplace: esServiceMarketplace,
                users: esUsers,
                notifications: esNotifications,
                profile: esProfile,
                signIn: esSignIn,
                dataSyncInfo: esDataSyncInfo,
                connectionAndChannel: esConnectionAndChannel,
                preferences: esPreferences,
                onesignal: esOnesignal,
                paymentOverview: esPaymentOverview,
                promotionPerformance: esPromotionPerformance
            }
        }
    },
    interpolation: {
        escapeValue: false
    }
});

export default i18n;