import React, { createContext, useEffect, useState, useCallback } from "react";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../i18n";

import { handleSignOut, getUserInfo, getUserToken } from "../../utils/loginUtils";
import {
  setCompanyPreferencesToLocalStorage,
  getCompanyPreferencesFromLocalStorage,
  setUserLanguagePreferenceToLocalStorage,
  getUserLanguagePreferenceFromLocalStorage,
} from "../../utils/preferencesUtils";

import { validateToken } from "../../services/odinService";
import { getCompanyPreferences } from "../../services/preferencesService";

import { LANGUAGE } from "../../options/preference";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [isCompanyOwnerUser, setIsCompanyOwnerUser] = useState(false);
  const [lang, setLang] = useState(LANGUAGE.pt_BR);
  const [timezone, setTimezone] = useState("America/Sao_Paulo");
  const [currency, setCurrency] = useState("BRL");

  const configureCompanyPreferences = useCallback(
    async (isAutenticated, isCompanyUser) => {
      const companyPreferences = isAutenticated
        ? await getCompanyPreferences()
        : await getCompanyPreferencesFromLocalStorage();

      const userLanguagePreference = isCompanyUser ? null : getUserLanguagePreferenceFromLocalStorage();

      if (companyPreferences) {
        setLang(userLanguagePreference || companyPreferences.lang);
        setTimezone(companyPreferences.timezone);
        setCurrency(companyPreferences.currency);

        setCompanyPreferencesToLocalStorage(
          companyPreferences.lang,
          companyPreferences.timezone,
          companyPreferences.currency
        );
        i18n.changeLanguage(userLanguagePreference || companyPreferences.lang);
      } else if (userLanguagePreference) {
        setLang(userLanguagePreference);
        i18n.changeLanguage(userLanguagePreference);
      }
    },
    [i18n]
  );

  const setUserLanguagePreference = useCallback(
    (lang) => {
      setLang(lang);

      setUserLanguagePreferenceToLocalStorage(lang);

      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  const validateUserToken = useCallback(async () => {
    try {
      await validateToken();
    } catch (error) {
      toast.error(t("toast.session_expired"));
      handleSignOut();
    }
  }, [t]);

  const handleSendUserInfoToAppcues = useCallback((user) => {
    if (window.Appcues && user) {
      window.Appcues.identify(user.id, {
        company_id: user.company_id,
        company_name: user.company_name,
        user_type: user.user_type,
        user_role: user.role
      });
    }
  }, []);

  const handleSendUserInfoToGoogleAnalytics = useCallback((user) => {
    if (window.gtag && user) {
      window.gtag("set", {
        user_id: user.id,
        user_properties: {
          company_id: user.company_id,
          company_name: user.company_name,
          user_type: user.user_type,
          user_role: user.role
        },
      });
    }
  }, []);

  const handleSignInSuccess = async (user) => {
    setUser(user);
    setIsCompanyOwnerUser(user.is_company_owner_user);
    await configureCompanyPreferences(true, user.is_company_owner_user);
    handleSendUserInfoToAppcues(user);
    handleSendUserInfoToGoogleAnalytics(user);
  };

  useEffect(() => {
    const user = getUserInfo();
    const userToken = getUserToken();

    if (user && userToken) {
      setUser(user);
      validateUserToken();
    }
  }, [validateUserToken]);

  useEffect(() => {
    const user = getUserInfo();
    const userToken = getUserToken();
    setIsCompanyOwnerUser(user?.is_company_owner_user ?? false);

    configureCompanyPreferences(user && userToken, user?.is_company_owner_user ?? false);
    handleSendUserInfoToAppcues(user);
    handleSendUserInfoToGoogleAnalytics(user);
  }, [configureCompanyPreferences, handleSendUserInfoToAppcues, handleSendUserInfoToGoogleAnalytics]);

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        isCompanyOwnerUser,
        handleSignOut,
        handleSignInSuccess,
        lang,
        timezone,
        currency,
        configureCompanyPreferences,
        setUserLanguagePreference,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
