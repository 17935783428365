import api from "../../services/api";

import i18n from "../../i18n";

import * as alertModuleSetting from "../../options/alertModuleSetting";
import { getUsers } from "../../services/odinService";

export async function getDataSource() {
  try {
    const token = localStorage.getItem("token");

    const response = await api.get("/alerts/modules", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseUserModules = await api.get("/alerts/user_modules", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const modules = response.data;
    const userModules = responseUserModules.data;

    userModules.forEach(element => {
      const module_found = modules.find(module => module.id === element.module);
      element.module_name = module_found.name;
    });

    const activeUserModules = userModules
      .filter((userModule) => userModule.settings[userModule.module_name])
      .map((filteredUserModule) => filteredUserModule.module_name);

    const dataSource = modules
      .filter(
        (module) => activeUserModules.indexOf(module.name) !== -1 && alertModuleSetting.types[module.name]
      )
      .map((filteredModule) => {
        return {
          label: filteredModule.prettier_name,
          value: filteredModule.name,
        };
      });

    return dataSource;
  } catch (e) {
    if (e.response.status === 401) {
      window.location.href = "/";
    }
  }
}

export async function getUsersActive() {
  const response = await getUsers();

  const users = response
    .filter((user) => user.settings.inactive === false)
    .map((user) => {
      return {
        value: user.id,
        label: `${user.contact_info.first_name} ${user.contact_info.last_name}`,
      };
    });

  return users;
}

export const vtex_dimension = {
  availableQuantity: "negative",
  reservedQuantity: "positive",
  totalQuantity: "negative",
};

let alertsPercentageMetrics = [];
let status = [];
let type = [];
let calculationMethods = [];
let newDateRange = [];

const updateOptions = (_) => {
  alertsPercentageMetrics = [
    //Google Analytics
    "bounceRate",
    "engagementRate",
    "itemListClickThroughRate",
    "itemPromotionClickThroughRate",
    "organicGoogleSearchClickThroughRate",
    "purchaserConversionRate",
    "purchaseToViewRate",
    "sessionConversionRate",
    "userConversionRate",
    //Google Ads
    "metrics.auction_insight_search_overlap_rate",
    "metrics.auction_insight_search_position_above_rate",
    "metrics.conversions_from_interactions_rate",
    "metrics.ctr",
    "metrics.engagement_rate",
    "metrics.interaction_rate",
    "metrics.invalid_click_rate",
    "metrics.phone_through_rate",
    "metrics.video_quartile_p100_rate",
    "metrics.video_quartile_p25_rate",
    "metrics.video_quartile_p50_rate",
    "metrics.video_quartile_p75_rate",
    "metrics.video_view_rate",
    "metrics.absolute_top_impression_percentage",
    "metrics.auction_insight_search_absolute_top_impression_percentage",
    "metrics.auction_insight_search_top_impression_percentage",
    //Facebook Ads
    "inline_link_click_ctr",
    "ctr",
    "outbound_clicks_ctr",
    "website_ctr",
    "qualifying_question_qualify_answer_rate",
    "estimated_ad_recall_rate_upper_bound",
    "estimated_ad_recall_rate_lower_bound",
    "canvas_avg_view_percent",
  ];

  status = [
    { value: "negative", label: i18n.t("alerts.fall") },
    { value: "positive", label: i18n.t("alerts.rise") },
    { value: "both", label: i18n.t("alerts.vary") },
    { value: "reach", label: i18n.t("alerts.reach") },
    { value: "dont_reach", label: i18n.t("alerts.not_reach") },
  ];

  type = [
    { value: "absolute", label: i18n.t("alerts.absolute_value") },
    { value: "percent", label: i18n.t("alerts.percentage") },
  ];

  calculationMethods = [
    { value: "media", label: i18n.t("alerts.average") },
    { value: "min", label: i18n.t("alerts.minimum") },
    { value: "max", label: i18n.t("alerts.maximum") },
    { value: "total", label: i18n.t("common.total") },
  ];

  newDateRange = [
    { value: "yesterday", label: i18n.t("alerts.yesterday"), prefix: i18n.t("alerts.from") },
    { value: "last_week", label: i18n.t("alerts.last_week_sun_sat"), prefix: i18n.t("alerts.from_the") },
    { value: "this_month_until_today", label: i18n.t("alerts.current_month"), prefix: "Do" },
    {
      value: "this_month_until_yesterday",
      label: i18n.t("alerts.current_month_until_yesterday"),
      prefix: "Do",
    },
    { value: "last_month", label: i18n.t("alerts.last_month"), prefix: "Do" },
    { value: "this_year_until_today", label: i18n.t("alerts.current_year"), prefix: "Do" },
    { value: "last_year", label: i18n.t("alerts.last_year"), prefix: "Do" },
    { value: "last_year_until_today", label: i18n.t("alerts.last_year_until_today"), prefix: "Do" },
    {
      value: "last_x_days_until_yesterday",
      label: i18n.t("alerts.last_x_days_until_yesterday"),
      prefix: i18n.t("alerts.from_the_masculine"),
    },
    {
      value: "last_x_days_until_today",
      label: i18n.t("alerts.last_x_days_until_today"),
      prefix: i18n.t("alerts.from_the_masculine"),
    },
    {
      value: "last_x_weeks",
      label: i18n.t("alerts.last_x_weeks_sun_sat"),
      prefix: i18n.t("alerts.from_the_feminine"),
    },
    {
      value: "last_x_months",
      label: i18n.t("alerts.last_x_months"),
      prefix: i18n.t("alerts.from_the_masculine"),
    },
    {
      value: "last_x_months_until_today",
      label: i18n.t("alerts.last_x_months_until_today"),
      prefix: i18n.t("alerts.from_the_masculine"),
    },
    {
      value: "last_x_years",
      label: i18n.t("alerts.last_x_years"),
      prefix: i18n.t("alerts.from_the_masculine"),
    },
    {
      value: "last_x_years_until_today",
      label: i18n.t("alerts.last_x_years_until_today"),
      prefix: i18n.t("alerts.from_the_masculine"),
    },
  ];
};

i18n.on("languageChanged", (lng) => {
  updateOptions();
});

updateOptions();

export { alertsPercentageMetrics, status, type, calculationMethods, newDateRange };
