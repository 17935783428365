import React, { useEffect, useCallback, useState, useContext } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import BackgroundNew from '../../components/BackgroundNew';
import FilterContainer from '../../components/FilterContainer';
import CardGroup from '../../components/CardGroup';
import CardIndicator from '../../components/CardIndicator';
import TableCampaign from '../../components/TableCampaign';
import LTVModalExplanation from '../../components/CustomModal/ModalLTVExplanation';
import ModalPageInformation from '../../components/CustomModal/ModalPageInformation';

import { DIMENSIONS_OPTONS, GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE } from '../../options/filterOptions';
import { getSelectedInitialDateRange, formatDate } from '../../utils/dateUtils';

import {
  getCRMBigNumbersMetrics,
  getCRMAverageTimeBetweenPurchasesMetrics,
  getMediaCampaignsMetrics,
  sendReportToEmailMediaCampaignsMetrics
} from '../../services/dashboardApiService';

import { AuthContext } from '../../contexts';

const MailMarketing = () => {
    const { t } = useTranslation();
    const { timezone, currency } = useContext(AuthContext);

    const [dateRange, setDateRange] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [selectedCampaignOptions, setSelectedCampaignOptions] = useState([]);
    const [selectedDimension, setSelectedDimension] = useState(DIMENSIONS_OPTONS.day);
    const [bigNumbersData, setBigNumbersData] = useState(null);
    const [campaignsData, setCampaignsData] = useState(null);
    const [showLTVModalExplanation, setShowLTVModalExplanation] = useState(false);
    const [openModalInformation, setOpenModalInformation] = useState(false);

    const [campaignsTableFullScreen, setCampaignsTableFullScreen] = useState(false);
    const [exportReportLoading, setExportReportLoading] = useState(false);

    const handleApplyFilter = async () => {
        const [startDate, endDate] = dateRange;
        const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;

        await getCRMDataMetrics(
            formatDate(startDate),
            formatDate(endDate),
            campaigns
        );
    };

    const getMediaCampaignsMetricsAllPages = async (filter) => {
      try {
          const response = await getMediaCampaignsMetrics(
              filter.initialDate,
              filter.finalDate,
              filter.timezone,
              filter.channels,
              filter.campaigns,
              filter.sourcesMedium,
              true,
              1
          );

          if (!response) { return null; }

          const totalPages = response.pagination.total_pages;

          const campaignsData = [...response.results];
          const available_campaigns_to_filter = response.available_campaigns_to_filter;

          for (let i = 2; i <= totalPages; i++) {
              const response = await getMediaCampaignsMetrics(
                  filter.initialDate,
                  filter.finalDate,
                  filter.timezone,
                  filter.channels,
                  filter.campaigns,
                  null,
                  true,
                  i
              );

              if (!response) { continue; }

              campaignsData.push(...response.results);
          }

          return {
              results: campaignsData,
              available_campaigns_to_filter
          }
      } catch(error) {
          throw new Error();
      }
  }

    const getCRMDataMetrics = useCallback(async (initialDate, finalDate, campaigns) => {
        setIsLoading(true);

        try {
            const DEFAULT_CHANNELS = ["mail"]
            const responses = await Promise.all([
              getCRMAverageTimeBetweenPurchasesMetrics(
                initialDate,
                finalDate,
                timezone
              ),
              getCRMBigNumbersMetrics(
                initialDate,
                finalDate,
                timezone
              ),
              getMediaCampaignsMetricsAllPages({
                initialDate,
                finalDate,
                timezone,
                channels: DEFAULT_CHANNELS,
                campaigns
              })
            ]);

            const responseAverageTimeBetweenPurchases = responses[0];
            const responseBigNumbers = responses[1];

            if (!responseAverageTimeBetweenPurchases || !responseBigNumbers) {
              setBigNumbersData(null);
              setCampaignOptions([]);
              setCampaignsData(null);

              return;
            }

            const allBigNumbers = {...responseBigNumbers, ...responseAverageTimeBetweenPurchases.big_numbers}
            setBigNumbersData({
                average_time_between_purchases: allBigNumbers.average_time_between_purchases,
                total_new_customers_count: allBigNumbers.new_customers.all_period.customers,
                total_rebuying_customers_count: allBigNumbers.rebuying_customers.all_period.customers,
                total_ltv: allBigNumbers.total_ltv,
                total_cac: allBigNumbers.total_cac,
                detail_calculation_indicators: allBigNumbers.detail_calculation_indicators
            });

            const responseCampaigns = responses[2];
            setCampaignsData(responseCampaigns.results)
            setCampaignOptions(responseCampaigns.available_campaigns_to_filter)
        } catch (error) {
            toast.error(error.message);
            setBigNumbersData(null);
            setCampaignOptions([])
            setCampaignsData(null)
        } finally {
          setIsLoading(false);
        }
    }, [timezone]);

    const handleExportReport = async() => {
        setExportReportLoading(true);
        try {
            toast.info(t('toast.wait_for_report'))

            const [startDate, endDate] = dateRange;
            const channels = ["mail"]
            const campaigns = selectedCampaignOptions.length > 0 ? [...selectedCampaignOptions] : null;

            await sendReportToEmailMediaCampaignsMetrics(
                formatDate(startDate),
                formatDate(endDate),
                GOOGLE_ANALYTICS_CAMPAIGN_REPORT_TYPE.EMAIL_MARKETING,
                timezone,
                channels,
                campaigns
            )
            toast.success(t('toast.report_preparation'));
        } catch (error) {
            toast.error(t('toast.report_generation_failed'));
        } finally {
            setExportReportLoading(false);
        }
    }

    const getCardIndicatorData = (data) => {
      return {
        keys: ["average_time_between_purchases", "total_new_customers_count", "total_rebuying_customers_count", "total_ltv", "total_cac"],
        titles: {
          average_time_between_purchases: t('common.average_time_between_purchases'),
          total_new_customers_count: t('common.new_customers'),
          total_rebuying_customers_count: t('common.returning_customers'),
          total_ltv: t('common.ltv'),
          total_cac: t('common.cac')
        },
        types: {
          average_time_between_purchases: 'decimal',
          total_new_customers_count: 'decimal',
          total_rebuying_customers_count: 'decimal',
          total_ltv: 'currency',
          total_cac: 'currency'
        },
        displayFlexSpaceOcupation: {
          average_time_between_purchases: 1,
          total_new_customers_count: 1,
          total_rebuying_customers_count: 1,
          total_ltv: 1,
          total_cac: 1
        },
        metricInfoConfig: {
          average_time_between_purchases: {
            visible: false,
            onClick: null
          },
          total_new_customers_count: {
            visible: false,
            onClick: null
          },
          total_rebuying_customers_count: {
              visible: false,
              onClick: null
            },
          total_ltv: {
            visible: true,
            onClick: () => setShowLTVModalExplanation(true)
          },
          total_cac: {
            visible: false,
            onClick: null
          }
        },
        data: data
      }
    }

    const onRenderComponent = useCallback(async () => {
      const { initialDateFilter, finalDateFilter } = getSelectedInitialDateRange();
      setDateRange([initialDateFilter, finalDateFilter]);

      await getCRMDataMetrics(
        formatDate(initialDateFilter),
        formatDate(finalDateFilter)
      );
    }, [getCRMDataMetrics]);

    useEffect(() => {
      onRenderComponent();
    }, [onRenderComponent]);

    return (
        <BackgroundNew
          titlePage={t('menu.email_marketing')}
          showInformation={true}
          informationTooltipTitle={t('common.click_to_learn_more')}
          informationOnClick={() => setOpenModalInformation(true)}
        >
            <FilterContainer
                multiSelectOptionsTitle={t('common.campaign')}
                showMultiSelectFilter={true}
                selectedDateRange={dateRange}
                setDateRange={setDateRange}
                multiSelectOptions={campaignOptions}
                setSelectedMultiSelectValueOptions={setSelectedCampaignOptions}
                selectedMultiSelectValueOptions={selectedCampaignOptions}
                onClickFilter={handleApplyFilter}
            />
            <CardIndicator
                data={getCardIndicatorData(bigNumbersData)}
                currency={currency}
                isLoading={isLoading}
            />
            <CardGroup
                title={t('menu.email_marketing')}
                tagIdentifierColor="#00CCAE"
                isLoading={isLoading}
            >
                <TableCampaign
                    data={campaignsData}
                    selectedDimension={selectedDimension}
                    setSelectedDimension={setSelectedDimension}
                    showDimensionController={true}
                    disabledDimensionController={campaignsData === null || campaignsData.length === 0}
                    showFullScreenOption={true}
                    fullScreenEnabled={campaignsTableFullScreen}
                    setFullScreenEnabled={setCampaignsTableFullScreen}
                    mailMarketing={true}
                    handleExportCSVReport={handleExportReport}
                    showExportCSVReportButton={true}
                    loadingExportCSVReportButton={exportReportLoading}
                    currency={currency}
                />
            </CardGroup>
            <LTVModalExplanation
              open={showLTVModalExplanation}
              handleClose={() => setShowLTVModalExplanation(false)}
              ltvDetailCalculation={bigNumbersData ? bigNumbersData.detail_calculation_indicators.ltv : null}
            />
            <ModalPageInformation
                title={t('mailMarketing.understand_email_marketing_analysis')}
                open={openModalInformation}
                handleClose={() => setOpenModalInformation(false)}
                videoUrl={"https://www.youtube.com/embed/cKKLV74RyGQ?si=a9SqI3uZsSooQ5R-"}
            />
        </BackgroundNew>
    );
}

export default MailMarketing;
