/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../i18n";

import Slide from "@mui/material/Slide";

import * as S from "./styles";

import BackgroundNew from "../../components/BackgroundNew";
import Loader from "../../components/Loader";

import { MdClose, MdAdd, MdFileDownload } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";

import {
  createSmartReportMinimumStockSettings,
  deleteSmartReportSettings,
  getTopHundredProductsModel,
  getSmartReportSettingsByFeatureId,
} from "../../services/smartReportService";

import { featureType } from "../../options/feature";

import { readFileAsync, getFileLines } from "../../utils/fileUtils";

function SmartReportMinimumStock() {
  const { t } = useTranslation();

  const [smartReportSettings, setSmartReportSettings] = useState(null);
  const [productsToMonitor, setProductsToMonitor] = useState([]);
  const [productsPages, setProductsPages] = useState([]);
  const [showProductsReachedTheMinimumStock, setShowProductsReachedTheMinimumStock] = useState(false);
  const [showUploadProductList, setShowUploadProductList] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productCSVFile, setProductCSVFile] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const PAGE_SIZE = 10;
  const INITIAL_PAGE = 1;
  const REGISTER_PRODUCT_LIMIT = 100;

  const loadSmartReportSettings = useCallback(async () => {
    setShowLoading(true);
    setShowProductsReachedTheMinimumStock(false);
    setPage(INITIAL_PAGE);
    setTotalPages(null);

    try {
      const response = await getSmartReportSettingsByFeatureId(featureType.SMART_REPORT_MINIMUM_STOCK);

      if (!response) {
        setProductsToMonitor([]);
        setSmartReportSettings(null);
        setProductsPages([]);
        return;
      }

      setSmartReportSettings(response);

      const pages = getProductsPages(response.settings.products_to_monitor);

      setProductsPages(pages);
      setTotalPages(pages.length);

      setProductsToMonitor(pages[0]);
    } catch (error) {
      setProductsToMonitor([]);
      setSmartReportSettings(null);

      toast.error(error.message);
    } finally {
      setShowLoading(false);
    }
  }, []);

  const handleCreateSmartReportSettingsFromFile = async () => {
    if (productCSVFile === null) {
      toast.error(t("toast.select_file_import"));
      return;
    }

    setEnableSaveButton(false);

    let contentCSV = await readFileAsync(productCSVFile);

    let linesCSVFile = getFileLines(contentCSV);

    linesCSVFile.shift(); // remove header

    linesCSVFile = linesCSVFile.filter((line) => line.trim() !== ""); // remover linhas em branco

    if (linesCSVFile.length === 0) {
      toast.error(t("toast.invalid_file"));

      setEnableSaveButton(true);

      return;
    }

    if (linesCSVFile.length > REGISTER_PRODUCT_LIMIT) {
      toast.error(
        `${t("toast.file_max_products")} ${REGISTER_PRODUCT_LIMIT} ${t("toast.products_check_try_again")}`
      );

      setEnableSaveButton(true);

      return;
    }

    try {
      const products = getProductsFromCSVFile(linesCSVFile);

      if (products.length === 0) {
        toast.error(t("toast.invalid_file"));

        setEnableSaveButton(true);

        return;
      }

      await createSmartReportMinimumStockSettings({
        products,
      });

      toast.success(t("toast.products_registered_success"));

      handleCloseModal();

      await loadSmartReportSettings();
    } catch {
      toast.error(t("toast.product_registration_error"));

      setEnableSaveButton(true);
    }
  };

  const handleDeleteSmartReportSettings = async () => {
    try {
      await deleteSmartReportSettings(smartReportSettings.id);

      toast.success(t("toast.products_deleted_success"));

      handleCloseModal();

      await loadSmartReportSettings();
    } catch {
      toast.error(t("toast.product_delete_error"));
    }
  };

  const handleDownloadTopHundredProductsModel = async () => {
    try {
      const response = await getTopHundredProductsModel();

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.setAttribute("href", url);
      link.setAttribute("download", "top_hundred_products_smart_report_model.csv");
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);

      toast.success(t("toast.template_download_success"));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getProductsPages = (products) => {
    let productsSplited = [];

    for (let i = 0; i < products.length; i += PAGE_SIZE) {
      productsSplited.push(products.slice(i, i + PAGE_SIZE));
    }

    return productsSplited;
  };

  const loadMoreProductsPage = (page) => {
    setPage(page);

    setProductsToMonitor([...productsToMonitor, ...productsPages[page - 1]]);
  };

  const filterProducts = (reachedTheMinimumStock) => {
    if (reachedTheMinimumStock) {
      const productsReachedTheMinimumStock = smartReportSettings.settings.products_to_monitor.filter(
        (product) => {
          return productIsReachedTheMinimumStock(product);
        }
      );

      const pages = getProductsPages(productsReachedTheMinimumStock);
      setTotalPages(pages.length);
      setProductsPages(pages);

      setProductsToMonitor(pages.length > 0 ? pages[0] : pages);
      setShowProductsReachedTheMinimumStock(true);
    } else {
      const pages = getProductsPages(smartReportSettings.settings.products_to_monitor);
      setTotalPages(pages.length);
      setProductsPages(pages);

      setProductsToMonitor(pages[0]);
      setShowProductsReachedTheMinimumStock(false);
    }

    setPage(INITIAL_PAGE);
  };

  const productIsReachedTheMinimumStock = (product) => {
    return product.last_registered_stock !== null && product.last_registered_stock <= product.minimum_stock;
  };

  const isValidProduct = (product) => {
    return product.sku && product.product_name && product.product_url && product.minimum_stock >= 0;
  };

  const getProductsFromCSVFile = (linesCSVFile) => {
    let products = [];

    for (const line of linesCSVFile) {
      const lineSplited = line.split(",");

      if (lineSplited.length === 0 || lineSplited.length !== 5) {
        break;
      }

      let productData = {
        sku: lineSplited[0],
        product_name: lineSplited[1],
        product_url: lineSplited[2],
        minimum_stock: parseInt(lineSplited[4]),
      };

      if (!isValidProduct(productData)) {
        products = [];
        break;
      }

      products.push(productData);
    }

    return products;
  };

  const isCompanyOwnerUserType = () => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    return userInfo.is_company_owner_user;
  };

  const handleCloseModal = () => {
    setShowUploadProductList(false);
    setShowDeleteModal(false);
    setProductCSVFile(null);
    setEnableSaveButton(true);
  };

  useEffect(() => {
    loadSmartReportSettings();
  }, [loadSmartReportSettings]);

  return (
    <BackgroundNew
      titlePage={t("menu.smart_report_minimum_stock")}
      subTitlePage={t("smartReportMinimumStock.track_stock_of_key_products")}
    >
      <S.Container>
        <div className="header">
          <div className="group">
            {!showLoading && isCompanyOwnerUserType() && !smartReportSettings && (
              <button type="button" onClick={() => setShowUploadProductList(!showUploadProductList)}>
                {t("smartReportMinimumStock.register_best_selling_products")}
                <MdAdd size={19} />
              </button>
            )}
            {!showLoading && smartReportSettings && (
              <button
                className={`filterProductsButton ${
                  !showProductsReachedTheMinimumStock ? "filterProductsDisabled" : ""
                }`}
                type="button"
                onClick={() => filterProducts(!showProductsReachedTheMinimumStock)}
              >
                {!showProductsReachedTheMinimumStock && t("smartReportMinimumStock.reached_minimum_stock")}
                {showProductsReachedTheMinimumStock && t("smartReportMinimumStock.all_products")}
                {!showProductsReachedTheMinimumStock && <RiFilterFill size={19} />}
                {showProductsReachedTheMinimumStock && <RiFilterOffFill size={19} />}
              </button>
            )}
            {!showLoading && isCompanyOwnerUserType() && smartReportSettings && (
              <button type="button" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                {t("smartReportMinimumStock.delete_products")}
                <FaTrashAlt size={19} />
              </button>
            )}
          </div>
        </div>

        <div className="workSpace">
          {!showLoading &&
            smartReportSettings &&
            productsToMonitor.length !== 0 &&
            productsToMonitor.map((product, index) => (
              <Slide direction="up" in mountOnEnter unmountOnExit>
                <div
                  className={`option ${productIsReachedTheMinimumStock(product) ? "isReached" : ""}`}
                  key={index}
                >
                  <div className="productData">
                    <img className="productImage" src={product.product_url} alt="Url do produto" />
                    <div className="descriptionOption">
                      <p className="titleProduct">{product.product_name}</p>
                      <p>SKU: {product.sku}</p>
                      <p>
                        {t("smartReportMinimumStock.minimum_stock")}: {product.minimum_stock}
                      </p>
                      <p>
                        {t("smartReportMinimumStock.last_recorded_stock")}:{" "}
                        {product.last_registered_stock !== null
                          ? product.last_registered_stock
                          : t("smartReportMinimumStock.no_update")}
                      </p>
                      <p>
                        {t("smartReportMinimumStock.updated_on")}:{" "}
                        {product.last_update || t("smartReportMinimumStock.no_update")}
                      </p>
                    </div>
                  </div>
                </div>
              </Slide>
            ))}
          {!showLoading && !smartReportSettings && (
            <div className="noProductsFound">
              <p>{t("smartReportMinimumStock.no_registered_products_yet")}</p>
            </div>
          )}
          {!showLoading && smartReportSettings && productsToMonitor.length === 0 && (
            <div className="noProductsFound">
              <p>{t("smartReportMinimumStock.no_products_found")}</p>
            </div>
          )}
          {showLoading && (
            <div className="loading">
              <Loader />
            </div>
          )}
        </div>

        {totalPages && page < totalPages && (
          <div className="pagination">
            <button className="loadMoreProductsPage" onClick={() => loadMoreProductsPage(page + 1)}>
              {t("common.load_more")}
            </button>
          </div>
        )}
      </S.Container>

      <S.UploadSmartReportSettingsModal open={showUploadProductList} onClose={handleCloseModal}>
        <div className="modal">
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">{t("smartReportMinimumStock.register_best_selling_products")}</p>
              <S.CloseButton type="button" title={t("common.close")} onClick={handleCloseModal}>
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>

          <div className="modal__subheader">
            <p>{t("smartReportMinimumStock.optimize_stock_control")}</p>
            <p>{t("smartReportMinimumStock.top_100_best_selling_products")}</p>
            <div className="smartReportStepByStep">
              <p>{t("smartReportMinimumStock.follow_steps_to_register")}</p>
              <ul>
                <li>1. {t("smartReportMinimumStock.download_template_file")}</li>
                <li>2. {t("smartReportMinimumStock.fill_minimum_stock_quantities")}</li>
                <li>3. {t("smartReportMinimumStock.upload_file")}</li>
                <li>4. {t("smartReportMinimumStock.click_save")}</li>
              </ul>
            </div>
            <p>{t("smartReportMinimumStock.done_registered_products")}</p>
            <p>{t("smartReportMinimumStock.clear_view_of_key_products")}</p>
          </div>

          <div className="modal__body">
            <div className="group">
              <div className="input">
                <p>{t("common.upload_file")}*</p>
                <input
                  type="file"
                  id="usersList"
                  accept=".csv"
                  onChange={(event) => setProductCSVFile(event.target.files[0])}
                />
              </div>
              <div className="modal__body__download__group">
                <p>{t("common.download_template_file")}</p>
                <span
                  className="modal__body__download__group__button_download"
                  onClick={handleDownloadTopHundredProductsModel}
                >
                  {t("common.download_file")} <MdFileDownload size={16} />
                </span>
              </div>
            </div>
          </div>

          <div className="modal__footer">
            <button className="btn btn--cancel" type="button" onClick={handleCloseModal}>
              {t("common.cancel")}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={handleCreateSmartReportSettingsFromFile}
              disabled={!enableSaveButton}
            >
              {t("common.save")}
            </button>
          </div>
        </div>
      </S.UploadSmartReportSettingsModal>

      <S.DeleteSmartReportSettingsModal open={showDeleteModal} onClose={handleCloseModal}>
        <div className={`modal is--delete`}>
          <div className="modal__header">
            <div className="modal__header-inner-wrapper">
              <p className="titleModal">{t("smartReportMinimumStock.delete_registered_products")}</p>
              <S.CloseButton type="button" title={t("common.close")} onClick={handleCloseModal}>
                <MdClose size={25} color="#000" />
              </S.CloseButton>
            </div>
            <div className="line" />
          </div>
          <div className="modal__body">
            <p>
              {t("smartReportMinimumStock.confirm_delete_products")} <br />
              <strong>{t("common.this_action_cannot_be_undone")}</strong>
            </p>
          </div>
          <div className="modal__footer">
            <button className="btn btn--cancel" type="button" onClick={handleCloseModal}>
              {t("common.cancel")}
            </button>
            <button
              className="btn btn--save"
              type="button"
              onClick={() => {
                handleDeleteSmartReportSettings();
              }}
            >
              {t("common.confirm")}
            </button>
          </div>
        </div>
      </S.DeleteSmartReportSettingsModal>
    </BackgroundNew>
  );
}

export default SmartReportMinimumStock;
