import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import "../../../i18n";

import { InfoBar } from "./../../InfoBar";
import { CustomModalProfileLogin } from "../../CustomModal";
import { getCurrentUserInfo } from "./../../../utils/loginUtils";

function InfoBarInstallApp(props) {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(true);

  const handleClick = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const { is_company_owner_user, first_access_user } = getCurrentUserInfo();

    const validateLogin = async () => {
      if (is_company_owner_user) {
        setHasLoggedIn(!first_access_user);
      }
    };
    validateLogin();
  }, []);

  return (
    <>
      {!hasLoggedIn && (
        <>
          <InfoBar {...props} onClick={handleClick}>
            <span>
              {t("common.login_to_user_account")} <strong>{t("common.click_here")}</strong>{" "}
              {t("common.learn_more")}
            </span>
          </InfoBar>
          <CustomModalProfileLogin open={openModal} handleClose={closeModal} />
        </>
      )}
    </>
  );
}

export default InfoBarInstallApp;
